const CONFIG = 'config';
const CONFIG2 = 'config2';
const RELOAD = 'reloadConfig';
const SETING = 'setting';
const ACCOUNT = 'account';
const AuthInitialState = {
  sidebar: null,
  sidebar2: null,
  setting: null,
}

export const setSidebar = (sidebar) => ({
  type: CONFIG,
  sidebar,
})
export const setSidebar2 = (sidebar2) => ({
  type: CONFIG2,
  sidebar2,
})
export const setSetting = (setting) => ({
  type: SETING,
  setting,
})
export const setSidebarDump = (sidebarDump) => ({
  type: RELOAD,
  sidebarDump,
})
export const setAccountType = (accountType) => ({
  type: ACCOUNT,
  accountType,
})

export const SidebarReducer = (state = AuthInitialState, action) => {
  switch(action.type) {
    case CONFIG:
      return {
        ...state,
        sidebar: action.sidebar,
      }
    case CONFIG2:
      return {
        ...state,
        sidebar2: action.sidebar2,
      }
    case RELOAD:
      return {
        ...state,
        sidebarDump: action.sidebarDump,
      }
    case SETING:
      return {
        ...state,
        setting: action.setting,
      }
    case ACCOUNT:
      return {
        ...state,
        accountType: action.accountType,
      }
    default:
      return state;
  }
}