import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import { Box, Button, Card, Grid, useMediaQuery, Modal } from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBox from "components/flexbox/FlexBox";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { H4, H6, H7 } from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import TermsModalButton from "page/modal/terms-modal";
import IconX from "assets/icon-close-modal.svg";
import SurveyCompleteModal from "page/modal/survey-complete-modal";
import bigBanner from "assets/ceoland/banner-big.png";
import mobileBanner from "assets/ceoland/banner-mobile.png";
import axios from "axios";

export default function SurveyModal({ open, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const [companyName, setCompanyName] = useState(""); 
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "48%",
    maxHeight: "90vh",
    overflowY: "auto",
    borderWidth: 0,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
    "&:focus-visible": {
      outline: "none",
    },
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = (e) => {
    if (!isChecked) {
      alert("개인정보 취급방침 및 수집, 이용에 동의해주세요.");
      return;
    }
    if(phone.length != 11) {
      alert("핸드폰번호는 11자리여야합니다.");
      return;
    }
    let domain = window.document.domain+"/"+window.location.pathname.replace('/', '')
    let str = domain;

    if (str.endsWith('/')) {
      str = str.slice(0, -1);
    }
    axios.post("https://api.sag.ceo/v4/business-survey/register", {
      domain: 'ceoland.kr',
      info: {
        업체대표: name,
        휴대폰: phone,
      }
    })
    setShowModal(false);
    setShowCompleteModal(true);

  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  return (
    <>
      {showCompleteModal ? (
        <SurveyCompleteModal
          showModal={showCompleteModal}
          setShowModal={setShowCompleteModal}
        />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          closeOnClick={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            backdropFilter: "blur(5px)",
          }}
        >
          <Grid container sx={style}>
            <Grid item xs={12}>
              <Card>
                <img
                  onClick={() => setShowModal(false)}
                  src={IconX}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "22px",
                    right: "22px",
                  }}
                />
                <FlexBox
                  sx={{
                    backgroundSize: "100% auto",
                    backgroundColor: "transparent",
                    borderWidth: 0,
                  }}
                >
                  <img
                    src={isMobile ? mobileBanner : bigBanner}
                    style={{ width: "100%", height: "100%" }}
                  />
                </FlexBox>

                <Grid container gap={3} py={4} px={isMobile ? 2 : 4}>
                  
                  <Grid item xs={12}>
                    <FlexRowAlign
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <H6 mb={1}>
                        1. 이름을 알려주세요
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>
                      <H7 mb={1} style={{ color: theme.palette.primary.red }}>
                        답변 필수 *
                      </H7>
                    </FlexRowAlign>
                    <AppTextField
                      fullWidth
                      name="name"
                      placeholder="이름을 알려주세요"
                      value={name}
                      onChange={(e) => setName(e.target.value.slice(0,4))}
                      sx={{
                        backgroundColor:
                          theme.palette.primary.lightBlueBackground,
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontWeight: 300,
                          fontSize: 15.5,
                          color: "#B5B7C0",
                        },
                      }}
                      inputProps={{ style: { height: 18 } }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FlexRowAlign
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <H6 mb={1}>
                        2. 핸드폰번호를 알려주세요
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>
                      <H7 mb={1} style={{ color: theme.palette.primary.red }}>
                        답변 필수 *
                      </H7>
                    </FlexRowAlign>
                    <AppTextField
                      fullWidth
                      name="phone"
                      placeholder="핸드폰번호를 알려주세요"
                      value={phone}
                      onChange={(e) => {
                        let val = e.target.value.replace(/\D/g, '')
                        if (val.startsWith("0")) {
                          val = "0" + val.slice(1,11)
                        } else {
                          val = val.slice(0, 11)
                        }
                        setPhone(val)
                      }}
                      sx={{
                        backgroundColor:
                          theme.palette.primary.lightBlueBackground,
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontWeight: 300,
                          fontSize: 15.5,
                          color: "#B5B7C0",
                        },
                      }}
                      inputProps={{ style: { height: 18 } }}
                    />
                  </Grid>

                  <Grid Container pb={1} sx={{ width: "100%" }}>
                    {isMobile && (
                      <Grid
                        ml={0}
                        mb={2}
                        container
                        xs={12}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                      >
                        <Grid item width={isMobile ? "100%" : 400}>
                          <Button
                            onClick={() => onSubmit()}
                            variant="contained"
                            size="small"
                            sx={{
                              fontSize: 15.5,
                              fontWeight: 500,
                              minWidth: isMobile ? "100%" : 400,
                              width: isMobile ? "100%" : 400,
                              borderRadius: 2,
                              paddingY: isMobile ? 1.2 : 0.8,
                              backgroundColor: "primary.darkBlue",
                              "&:hover": {
                                backgroundColor: "primary.darkBlueHover",
                              },
                            }}
                          >
                            상담하기
                          </Button>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FlexRowAlign
                        gap={0}
                        sx={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: isMobile ? "flex-start" : "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            direction: "row",
                            alignItems: "center",
                          }}
                        >
                          <AppCheckBox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <H7
                            ml={1}
                            sx={{ textAlign: "left", lineHeight: 1.4 }}
                          >
                            개인정보 취급방침 및 수집, 이용에 동의
                          </H7>
                        </Box>
                        <Box
                          ml={isMobile ? 0.5 : 4}
                          sx={{
                            display: "flex",
                            direction: "row",
                            alignItems: "center",
                          }}
                        >
                          <TermsModalButton />
                        </Box>
                      </FlexRowAlign>
                    </Grid>

                    {!isMobile && (
                      <Grid
                        ml={0}
                        mt={4}
                        container
                        xs={12}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                      >
                        <Grid item width={isMobile ? "100%" : 400}>
                          <Button
                            onClick={() => onSubmit()}
                            variant="contained"
                            size="small"
                            sx={{
                              fontSize: 15.5,
                              fontWeight: 500,
                              minWidth: isMobile ? "100%" : 400,
                              width: isMobile ? "100%" : 400,
                              borderRadius: 2,
                              paddingY: isMobile ? 1.2 : 0.8,
                              backgroundColor: "primary.darkBlue",
                              "&:hover": {
                                backgroundColor: "primary.darkBlueHover",
                              },
                            }}
                          >
                            상담하기
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
}
