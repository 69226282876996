import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { Small } from "./Typography";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function BottomBar({setShowMobileSideBar, showMobileSideBar}) {
  const setting = useSelector((state) => state.Sidebar.setting);
  const theme = useTheme();
  const navigate = useNavigate()
  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "#fff",
        top: "auto",
        bottom: 0,
        borderTopRightRadius: "24px",
        borderTopLeftRadius: "24px",
      }}
    >
      <Toolbar sx={{ padding: 0, paddingBottom: "4px" }}>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="flex-end"
          width="100%"
        >
          {setting?.bottom_bar?.map((item, index) => (
            <Box
              key={index}
              onClick={() => {
                if (item?.link) {
                  navigate(item.link);
                } else if (item.title == "더보기") {
                  setShowMobileSideBar(true)
                }
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <IconButton sx={{ paddingBottom: "20px" }}>
                <img
                  src={item.title == "메뉴" ? (showMobileSideBar ? require('assets/icon/'+item.selectedIcon) : require('assets/icon/'+item.icon)) : item.link == window.location.pathname ? require('assets/icon/'+item.selectedIcon) : require('assets/icon/'+item.icon)}
                  alt={item.title}
                  style={{ height: item.height }}
                />
              </IconButton>
              <Small color={
                  item.link == window.location.pathname
                    ? "#5313BB"
                    : theme.palette.primary.dark
                } sx={{ fontSize: "11px", position: "absolute", bottom: 4.5 }}>
                {item.title}
              </Small>
            </Box>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
