import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import { Button, Card, Grid, useMediaQuery, Modal } from "@mui/material";
// ----------------------------------
import kakaoLoginPage from "assets/jiwongum/kakao-login-page.png";
// ----------------------------------
import {useDispatch} from "react-redux";
import ImageEdit from "./ImageEdit";
import IconEdit from "./IconEdit";

export default function IconEditModal({ index, showModal, setShowModal, onClose}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "105%" : "30%",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    boxShadow: 24,
    p: 0,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };
  return (
    <>
      
      <Modal
        open={showModal}
        onClose={() => {setShowModal(false)}}
        closeOnClick={true}
        disableEnforceFocus={true}
        disableBackdropClick={false} // Disable closing when clicking on the backdrop
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(5px)", backgroundColor: "transparent" }}
      >
        <Card container spacing={2} style={style}>
          <IconEdit index={1} closeModal={() => {
            if (onClose) {
              onClose()
            }
            setShowModal(false)
          }}/>
        </Card>
      </Modal>
    </>
  );
}

// 버튼

{
  /* <Grid item width={"100%"}>
                      <Button
                        // type="submit"
                        variant="contained"
                        size="small"
                        sx={{
                          fontSize: 15.5,
                          fontWeight: 500,
                          borderRadius: 2,
                          minWidth: "100%",
                          width: "100%",
                          borderRadius: 2,
                          backgroundColor: "primary.naver",
                          "&:hover": {
                            backgroundColor: "primary.naver",
                          },
                        }}
                      >
                        <img
                          src={naver}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        />
                        네이버 로그인
                      </Button>
                    </Grid> */
}
