import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  styled,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { ButtonText, H5, Tiny } from "components/Typography";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { useRef, useState } from "react";
import { Minus, Plus } from "react-feather";
import { useSelector } from "react-redux";
import { events, stream } from 'fetch-event-stream';
import { Button } from "react-scroll";
import toast, { Toaster } from "react-hot-toast";

export default function ImageEdit({
  index,
  classData,
  closeModal
}) {
  const token = useSelector((state) => state.Auth.token);
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [uploading , setUploading] = useState(false);
  const [video_url , setVideoUrl] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isLargeScreen = window.innerWidth > 1720;

  const UploadButton = styled(Box)(({ theme }) => ({
    width: "fit-content",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "6px",
    paddingRight: "6px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.palette.borderColor,
    backgroundColor: theme.palette.primary.grey700,
  }));

  const [title, setTitle] = useState("");
  const [selectedCurriculum, setSelectedCurriculum] = useState("");
  const [fileName, setFileName] = useState(null);

  const [videoFile, setVideoFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(classData.article_data?.thumbnail);
  const [runningTime, setRunningTime] = useState(0);
  

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
      axios.post(process.env.REACT_APP_API_URI+'/v4/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      }).then((res) => {
        const url = res.data.data.file_url
        setThumbnail(url)
      })
  }

  const handleUploadVideo = () => {
    if (thumbnail === null) {
      toast.error("썸네일을 업로드해 주세요");
      return;
    }
    const data = {
      thumbnail: thumbnail
    }
    axios.patch(process.env.REACT_APP_API_URI+'/v4/course/class/'+classData.article_data.id, data, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((res) => {
      if (res.data.message === "강의를 수정했습니다.") {
        toast.success("강의를 수정했습니다.");
        closeModal()
      } else {
        toast.error("강의 수정에 실패했습니다.");
      }
    })
  }
  return (
    <div style={{
      padding: 10
    }}>

      {/* <FlexRowAlign
        mb={3}
        gap={2}
        sx={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        <H5 mr={1}>{index + 1}강</H5>
      </FlexRowAlign> */}

      <Grid container spacing={4}>
        
        <Grid item xs={12}>
          <ButtonText mb={1}>썸네일 업로드</ButtonText>
          <img src={thumbnail} style={{width: "100%", height: "auto",
          borderRadius: 4,
          border: "1px solid",
          borderColor: theme.palette.borderColor,

        }}/>

          <Box
            px={1}
            gap={0}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid",
              borderColor: theme.palette.borderColor,
              width: isMobile ? "100%" : "fit-content",
              backgroundColor: "#ffffff",
              minWidth: isMobile ? 0 : 100,
              height: "50px",
              borderRadius: 2,
            }}
          >
            <UploadButton>
              <label htmlFor="upload-btn">
                <input
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  accept="image/*" // Make sure this line is updated
                  id="upload-btn"
                  type="file"
                  style={{ display: "none" }}
                  disabled={uploading}
                />
                <IconButton component="span" disableRipple>
                  <Tiny
                    sx={{
                      fontWeight: 300,
                      color: theme.palette.primary.dddgray400,
                    }}
                  >
                    파일 선택
                  </Tiny>
                </IconButton>
              </label>
            </UploadButton>
          </Box>
        </Grid>
        

        <Grid item sm={6} xs={12}>
          <Button
          style={{
            // backgroundColor: "#FFD600",
            color: "#000000",
            borderRadius: "8px",
            width: "100%",
            height: "50px",
            fontSize: "14px",
            fontWeight: "300",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            handleUploadVideo()
          }}
          >
            썸네일 설정
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
