import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  useMediaQuery,
  Modal,
} from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBox from "components/flexbox/FlexBox";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import {
  H4,
  H5,
  H6,
  H7,
  Small,
  TableContent,
  Tiny,
} from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import TermsModalButton from "page/modal/terms-modal";
import IconX from "assets/icon-close-modal.svg";
import SurveyCheckButton from "components/SurveyCheckButton";
import { Clock } from "react-feather";
import roundx from "assets/roundx.png";
import SurveyCompleteModal from "page/modal/survey-complete-modal";
import axios from "axios";

export default function SurveyJdoctor({ open, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const [q0, setQ0] = useState(0)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [q3, setQ3] = useState(0)
  const [q4, setQ4] = useState(0)
  const [q5, setQ5] = useState(0)
  const [q6, setQ6] = useState(0)
  const [q7, setQ7] = useState(0)
  const [about, setAbout] = useState("")
  const [accept, setAccept] = useState(true)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "48%",
    maxHeight: "90vh",
    overflowY: "auto",
    // borderRadius: 4,
    // border: "1px solid #E1E1E1",
    // p: 4,
    borderWidth: 0,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0", // Adjust the width as needed
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = () => {
    if(!accept) return alert("개인정보 수집 동의를 체크해주세요.")

    if (!q0 || !q3 || !q4 || !q5 || !q6 || !q7 || name.length < 2 || phone.length < 6) {
      return alert("모든 답변을 완료해주세요.")
    }

    let info = {}

    if(q0 == 1) info["상담 종류"] = "보험가입"
    if(q0 == 2) info["상담 종류"] = "보장분석"
    if(q0 == 3) info["상담 종류"] = "설계사 입사"
    if(q0 == 4) info["상담 종류"] = "교육문의"

    info["이름"] = name
    
    info["연락처"] = phone

    if(q3 == 1) info["신청 경로"] = "유튜브"
    if(q3 == 2) info["신청 경로"] = "네이버 (카페, 블로그)"
    if(q3 == 3) info["신청 경로"] = "소개 및 지인"

    if(q4 == 1) info["인지 시기"] = "1주일 이내"
    if(q4 == 2) info["인지 시기"] = "1개월 이내"
    if(q4 == 3) info["인지 시기"] = "1년 이상"

    if(q5 == 1) info["금융이해도"] = "이제 막 관심을 갖기 시작했다"
    if(q5 == 2) info["금융이해도"] = "기본적인 용어정도는 알아 들을수 있다"
    if(q5 == 3) info["금융이해도"] = "보험의 우선순위를 알고, 기준을 잡을수 있다"

    if(q6 == 1) info["월보험료"] = "10만원 이하"
    if(q6 == 2) info["월보험료"] = "30만원 이하"
    if(q6 == 3) info["월보험료"] = "50만원 이하"
    if(q6 == 4) info["월보험료"] = "100만원 이하"

    if(q7 == 1) info["건강상태"] = "3개월이내 검진이나 병원 내원력"
    if(q7 == 2) info["건강상태"] = "1년이내 재검사 또는 추가검사"
    if(q7 == 3) info["건강상태"] = "5년이내 입원, 수술, 30일이상 투약, 7일이상 치료, 10대질병진단 등"
    if(q7 == 4) info["건강상태"] = "매우 건강함"

    info["기타 특이사항"] = about

    axios.post("https://api.정닥터.com/v4/survey/insurance", {
      info: info
    }).then((res) => {
      setShowCompleteModal(true);
    })
  };

  return (
    <>
      {showCompleteModal ? (
        <SurveyCompleteModal
          showModal={showCompleteModal}
          setShowModal={setShowCompleteModal}
          onExit={() => setShowModal(false)}
        />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          closeOnClick={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <Grid container sx={style}>
            <Grid item xs={12}>
              <Card>
                <img
                  onClick={() => setShowModal(false)}
                  src={IconX}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "22px",
                    right: "22px",
                  }}
                />
                <FlexBox
                  sx={{
                    backgroundColor: theme.palette.primary.darkBlue,
                    // borderRadius: 4,
                    borderWidth: 0,
                  }}
                >
                  <Box
                    pt={3}
                    pb={6}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: isMobile
                        ? "50px 20px 32px 20px"
                        : "50px 32px 32px 32px",
                      // borderWidth: 0,
                    }}
                  >
                    <H4 mb={2.5} sx={{ color: "white" }}>
                      보험상담 설문지
                    </H4>

                    <H7 sx={{ color: "white", fontWeight: 400 }}>
                      제안하실 의견 및 문의 사항을 접수해주세요.
                    </H7>
                    <FlexRowAlign
                      gap={1}
                      mt={1}
                      sx={{
                        backgroundColor: "#2868B0",
                        padding: "7px 14px 7px 14px",
                        borderRadius: 100,
                      }}
                    >
                      <Clock
                        color="white"
                        size={18}
                        style={{ marginRight: "0px", marginBottom: "2px" }}
                      />
                      <H7 sx={{ color: "white", fontWeight: 300 }}>
                        2023.12.29. 오후 04:20 ~ 제한 없음
                      </H7>
                    </FlexRowAlign>
                  </Box>
                </FlexBox>
                  <Grid container spacing={3} py={4} px={isMobile ? 2 : 4}>
                    <Grid item xs={12}>
                      <H6 mb={1}>
                        1. 상담 목적을 알려주세요
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>

                      <Grid container gap={2}>
                        <SurveyCheckButton title="보험가입" checked={q0 == 1} onChange={()=>{setQ0(1)}}/>
                        <SurveyCheckButton title="보장분석" checked={q0 == 2} onChange={()=>{setQ0(2)}}/>
                        <SurveyCheckButton title="설계사 입사" checked={q0 == 3} onChange={()=>{setQ0(3)}}/>
                        <SurveyCheckButton title="교육문의" checked={q0 == 4} onChange={()=>{setQ0(4)}}/>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FlexRowAlign
                        sx={{ justifyContent: "space-between", width: "100%" }}
                      >
                        <H6 mb={1}>
                          2. 이름 남겨주세요
                          <span style={{ color: theme.palette.primary.red }}>
                            {" "}
                            *
                          </span>
                        </H6>
                      </FlexRowAlign>
                      <AppTextField
                        fullWidth
                        name="name"
                        placeholder="이름을 입력해 주세요"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value.slice(0,4))
                        }}
                        sx={{
                          backgroundColor:
                            theme.palette.primary.lightBlueBackground,
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 18 } }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <H6 mb={1}>
                        3. 연락처 남겨주세요
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>
                      <AppTextField
                        fullWidth
                        name="phone"
                        placeholder="연락처를 입력해 주세요"
                        value={phone}
                        onChange={(e) => {
                          let val = e.target.value.replace(/\D/g, '')
                          if (val.startsWith("0")) {
                            val = "0" + val.slice(1,11)
                          } else {
                            val = val.slice(0, 11)
                          }
                          setPhone(val)
                        }}
                        sx={{
                          backgroundColor:
                            theme.palette.primary.lightBlueBackground,
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 18 } }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <H6 mb={1}>
                        4. 어떤 경로로 문의를 하시게 되었나요?
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>

                      <Grid container gap={2}>
                        <SurveyCheckButton title="유튜브" checked={q3 == 1} onChange={()=>{setQ3(1)}}/>
                        <SurveyCheckButton title="네이버 (카페, 블로그)" checked={q3 == 2} onChange={()=>{setQ3(2)}}/>
                        <SurveyCheckButton title="소개 및 지인" checked={q3 == 3} onChange={()=>{setQ3(3)}}/>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <H6 mb={1}>
                        5. 정박사 방송은 언제부터 알게 되셨나요?
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>

                      <Grid container gap={2}>
                        <SurveyCheckButton title="1주일 이내" checked={q4 == 1} onChange={()=>{setQ4(1)}}/>
                        <SurveyCheckButton title="1개월 이내" checked={q4 == 2} onChange={()=>{setQ4(2)}}/>
                        <SurveyCheckButton title="1년 이상" checked={q4 == 3} onChange={()=>{setQ4(3)}}/>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <H6 mb={1}>
                        6. 금융및 보험관련해서 어느정도 이해도가 있다고
                        생각하시나요?
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>

                      <Grid container gap={2}>
                        <SurveyCheckButton title="이제 막 관심을 갖기 시작했다" checked={q5 == 1} onChange={()=>{setQ5(1)}}/>
                        <SurveyCheckButton title="기본적인 용어정도는 알아 들을수 있다" checked={q5 == 2} onChange={()=>{setQ5(2)}}/>
                        <SurveyCheckButton title="보험의 우선순위를 알고, 기준을 잡을수 있다" checked={q5 == 3} onChange={()=>{setQ5(3)}}/>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <H6 mb={1}>
                        7. 월보험료는 어느정도 선이면 적정하다고 생각하시나요?
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>

                      <Grid container gap={2}>
                        <SurveyCheckButton title="10만원 이하" checked={q6 == 1} onChange={()=>{setQ6(1)}}/>
                        <SurveyCheckButton title="30만원 이하" checked={q6 == 2} onChange={()=>{setQ6(2)}}/>
                        <SurveyCheckButton title="50만원 이하" checked={q6 == 3} onChange={()=>{setQ6(3)}}/>
                        <SurveyCheckButton title="100만원 이하" checked={q6 == 4} onChange={()=>{setQ6(4)}}/>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <H6 mb={1}>
                        8. 현재 건강상태에 대해 해당되는 부분이 있다면 체크해
                        주세요
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </H6>

                      <Grid container gap={2}>
                        <SurveyCheckButton title="매우 건강함" checked={q7 == 4} onChange={()=>{setQ7(4)}}/>
                        <SurveyCheckButton title="3개월이내 검진이나 병원 내원력" checked={q7 == 1} onChange={()=>{setQ7(1)}}/>
                        <SurveyCheckButton title="1년이내 재검사 또는 추가검사" checked={q7 == 2} onChange={()=>{setQ7(2)}}/>
                        <SurveyCheckButton
                          title="   5년이내 입원, 수술, 30일이상 투약, 7일이상 치료,
                          10대질병진단 등" checked={q7 == 3} onChange={()=>{setQ7(3)}}
                        />
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <FlexRowAlign
                        sx={{ justifyContent: "space-between", width: "100%" }}
                      >
                        <H6 mb={1}>9. 기타 특이사항을 남겨주세요</H6>
                      </FlexRowAlign>
                      <AppTextField
                        fullWidth
                        multiline
                        name="etc"
                        placeholder=""
                        value={about}
                        onChange={(e) => {
                          setAbout(e.target.value)
                        }}
                        sx={{
                          backgroundColor:
                            theme.palette.primary.lightBlueBackground,
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 54 } }}
                      />
                    </Grid>
                  </Grid>
              </Card>
            </Grid>

            {/* 두번째 박스 */}
            <Grid item xs={12} mt={2}>
              <Card sx={{ marginTop: "20px" }}>
                <Grid Container py={4} px={isMobile ? 2 : 4}>
                  <Grid item xs={12} mb={5}>
                    <FlexRowAlign
                      gap={1}
                      mt={2}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: isMobile ? "flex-start" : "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FlexRowAlign
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <img src={roundx} width={20} />
                        <TableContent
                          ml={1.5}
                          style={{
                            color: theme.palette.primary.dark,
                            whiteSpace: "normal",
                          }}
                        >
                          보험상담 제공을 위해 아래의 정보를 수집하며, 수집된
                          정보는 보험상담 목적외에는 사용되지 않습니다.
                        </TableContent>
                      </FlexRowAlign>
                      <Box
                        ml={isMobile ? 0.5 : 0}
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      >
                        <TermsModalButton type={"survey"}/>
                      </Box>
                    </FlexRowAlign>
                  </Grid>

                  <Grid item xs={12}>
                    <FlexBox
                      gap={1}
                      mt={2}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: isMobile ? "flex-start" : "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      >
                        <TableContent
                          ml={1.5}
                          style={{
                            color: theme.palette.primary.dark,
                            whiteSpace: "normal",
                          }}
                        >
                          1.
                        </TableContent>
                        <TableContent
                          ml={1.5}
                          style={{
                            color: theme.palette.primary.dark,
                            whiteSpace: "normal",
                          }}
                        >
                          수집하는 개인정보 항목
                        </TableContent>
                      </Box>
                      <Box
                        ml={isMobile ? 3.5 : 0}
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      ></Box>
                    </FlexBox>
                  </Grid>

                  <Grid item xs={12} mb={3}>
                    <FlexBox
                      ml={3.5}
                      gap={1}
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: isMobile ? "flex-start" : "center",
                        // justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      >
                        <H7 ml={1}>이름, 연락처, 건강정보 등</H7>
                      </Box>
                    </FlexBox>
                  </Grid>

                  <Grid item xs={12}>
                    <FlexBox
                      gap={1}
                      mt={2}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: isMobile ? "flex-start" : "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      >
                        <TableContent
                          ml={1.5}
                          style={{
                            color: theme.palette.primary.dark,
                            whiteSpace: "normal",
                          }}
                        >
                          2.
                        </TableContent>
                        <TableContent
                          ml={1.5}
                          style={{
                            color: theme.palette.primary.dark,
                            whiteSpace: "normal",
                          }}
                        >
                          수집 및 이용목적
                        </TableContent>
                      </Box>
                      <Box
                        ml={isMobile ? 3.5 : 0}
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      ></Box>
                    </FlexBox>
                  </Grid>

                  <Grid item xs={12} mb={3}>
                    <FlexBox
                      ml={3.5}
                      gap={1}
                      mt={1}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: isMobile ? "flex-start" : "center",
                        // justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          direction: "row",
                          alignItems: "center",
                        }}
                      >
                        <H7 ml={1}>보험&보상 관련 상담및 서비스정보 제공 등</H7>
                      </Box>
                    </FlexBox>
                  </Grid>

                  <SurveyCheckButton title="동의합니다" checked={accept} onChange={() => setAccept(!accept)}/>

                  <Grid
                    ml={0}
                    mt={5}
                    container
                    xs={12}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Grid item width={isMobile ? "100%" : 250}>
                      <Button
                        // type="submit"
                        onClick={() => onSubmit()}
                        variant="contained"
                        size="small"
                        sx={{
                          fontSize: 15.5,
                          fontWeight: 500,
                          minWidth: isMobile ? "100%" : 240,
                          width: isMobile ? "100%" : 240,
                          borderRadius: 2,
                          backgroundColor: "primary.darkBlue",
                          "&:hover": {
                            backgroundColor: "primary.darkBlueHover",
                          },
                        }}
                      >
                        제출하기
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
}
