

export const userTypes = {
    "제한없음": 0,
    "개인": 4000,
    "개인 유료": 5000,
    "매니저": 8000,
    "정직원": 8500,
    "정직원 매니저": 8700,
    "이사": 9000,
    "마스터": 10000
}


export const userTypesN = {
    "개인": 4000,
    "개인 유료": 5000,
    "매니저": 8000,
    "정직원": 8500,
    "정직원 매니저": 8700,
}

const patnerTypes = {
    "마스터": 10000,
    "영업이사": 8000,
    "팀장": 4000,
    "FC": 2000,
}

export function accountPartnerPermissionCompare(userType, allowUserType) {
    try {
        if(allowUserType == "제한없음" || allowUserType == "" || allowUserType == null) return true
        if (patnerTypes[allowUserType] <= patnerTypes[userType]) {
            return true;
        } else {
            return false;
        }
    } catch(e) {
        return false;
    }
}
export function accountPermissionCompare(userType, allowUserType) {
    try {
        if (localStorage.getItem('persist:root')) {
            let persist = JSON.parse(localStorage.getItem('persist:root'));
            let user = JSON.parse(persist?.Sidebar|| '{}')?.accountType || {};

            if(allowUserType == "제한없음" || allowUserType == "" || allowUserType == null) return true
            if (!userType) return false
            if (user[allowUserType] <= user[userType]) {
                return true;
            } else {
                return false;
            }
        }
    } catch(e) {
        return false;
    }
}
export function accountPermissionCompare2(userType, allowUserType) {
    try {
        if (localStorage.getItem('persist:root')) {
            let persist = JSON.parse(localStorage.getItem('persist:root'));
            let user = JSON.parse(persist?.Sidebar|| '{}')?.accountType || {};
            if(allowUserType == "제한없음" || allowUserType == "" || allowUserType == null) return true
            if (user[allowUserType] < user[userType]) {
                return true;
            } else {
                return false;
            }
        }
    } catch(e) {
        return false;
    }
}