import React from "react";
import {
  Box,
  IconButton,
  InputBase,
  styled,
  useMediaQuery,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import SearchIcon from "icons/SearchIcon";
import logo from "assets/sugar_logo.png";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import { accountPermissionCompare } from "utils/compareAccount";
import { useState } from "react";
import IconEditModal from "components/modal/iconEditorModal";

const BigSearchBar2 = ({ open, handleClose, isHeader }) => {
  const setting = useSelector((state) => state.Sidebar.setting);
  const account = useSelector((state) => state.Auth.account);
  const theme = useTheme();
  const lessThan1500 = useMediaQuery("(max-width:1500px)");
  const lessThan1200 = useMediaQuery("(max-width:1200px)");
  const lessThan1300 = useMediaQuery("(max-width:1300px)");
  const lessThan900 = useMediaQuery("(max-width:900px)");
  const lessThan600 = useMediaQuery("(max-width:600px)");
  const lessThan400 = useMediaQuery("(max-width:400px)");

  const StyledInputContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    maxWidth: "1100px",
    alignItems: "center",
    height: "40px",
  }));

  const GradientBorderSVG = styled("svg")(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    "& rect": {
      fill: "none",
      stroke: "url(#gradient)",
      strokeWidth: 2.5,
      rx: lessThan1500 ? 44 : 44,
    },
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: "100%",
    height: "36px",
    fontSize: 14,
    fontWeight: 500,
    paddingTop: "3px",
    paddingLeft: "16px",
    paddingRight: "8px",
    position: "relative",
    zIndex: 1,
    "& input::placeholder": {
      fontWeight: 400,
      fontSize: 15,
      color: "#B5B7C0",
    },
  }));

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const [showIconEdit, setShowIconEdit] = useState(false);
  return (
    <StyledInputContainer>
    <IconEditModal showModal={showIconEdit} setShowModal={setShowIconEdit} />
      <GradientBorderSVG
        viewBox={
          isHeader && lessThan1300
            ? `0 0 1400 100`
            : isHeader && lessThan1500
            ? `0 0 1600 100`
            : lessThan400
            ? `0 0 700 100`
            : lessThan600
            ? `0 0 1100 100`
            : lessThan900
            ? `0 0 1500 100`
            : lessThan1200
            ? `0 0 1900 100`
            : lessThan1500
            ? `0 0 1800 100`
            : `0 0 2000 100`
        }
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: "#B187FF", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: "#5C92D2",
                stopOpacity: 1,
              }}
            />
          </linearGradient>
        </defs>
        <rect
          x="1.5"
          y="1.5"
          width="calc(100% - 3px)"
          height="calc(100% - 3px)"
        />
      </GradientBorderSVG>
      <StyledInputBase
        placeholder="키워드를 검색해 보세요"
        startAdornment={
          <NavLink to={`/`}>
          {
            accountPermissionCompare(account?.type, "정직원 매니저") ? (
              <>
              <ContextMenuTrigger id="context-logo2" key={2}>
                  <img
              height={"30px"}
                    src={setting?.logo}
                    alt={"logo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                    }}
                  />
              </ContextMenuTrigger>
              <ContextMenu id="context-logo2">
                <ContextMenuItem onClick={() => {
                  setShowIconEdit(true)
                }}>
                  아이콘 수정
                </ContextMenuItem>
              </ContextMenu>
              </>
            ) : (
              <img
              height={"30px"}
                src={setting?.logo}
                alt={"logo"}
                style={{
                  cursor: "pointer",
                  marginLeft: "2px",
                }}
              />
            )
          }
          </NavLink>
        }
        endAdornment={
          <StyledIconButton onClick={() => {}}>
            <SearchIcon
              sx={{
                color: "#B5B7C0",
              }}
            />
          </StyledIconButton>
        }
      />
    </StyledInputContainer>
  );
};

export default BigSearchBar2;
