import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  useMediaQuery,
  Modal,
} from "@mui/material";
// ----------------------------------
import naver from "assets/fab-naver.png";
import kakao from "assets/fab-kakao.png";
import insta from "assets/fab-insta.png";
import youtube from "assets/fab-youtube.png";
import logo from "assets/sugar_logo.png";
// ----------------------------------
import AppTextField from "components/input-fields/AppTextField";
import { H5, H6, Small, TableContent, Tiny } from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup"; // styled components
import FlexRowAlign from "components/flexbox/FlexRowAlign";
// import FindAccountModal from "./findAccount-modal";
import IconX from "assets/icon-close-modal.svg";

import SignupModal from "page/modal/signup-modal";

import { Cookies } from "react-cookie";
import Request from "utils/request";
import CompleteModal from "./alerts/SuccessModal";
import WarningModal from "./alerts/WarningModal";
import { useSelector } from "react-redux";
import { setLogin } from "reducer/authReducer";
import { useDispatch } from "react-redux";

const cookies = new Cookies();

export default function LoginModalSimple({ open, setShowLoginModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const setting = useSelector((state) => state.Sidebar.setting);

  const dispatch = useDispatch()

  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);

  const [showFindAccountModal, setShowFindAccountModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "35%",
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #E1E1E1",
    boxShadow: 24,
    p: isMobile ? 4 : 8,
    paddingLeft: isMobile ? 2 : 4,
    paddingRight: isMobile ? 2 : 4,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0.em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  
  const login = () => {
    
    const request = new Request()
    request.post(process.env.REACT_APP_API_URI+'/v4/auth/login', {
      email: userEmail,
      password: userPassword
    }).then((res) => {
      if(res.data.success == false) {
        alert(res.data.message)
        eval(res.data?.action || "")
      } else {
        setUserAccount(res.data.data.user)
        setUserToken(res.data.data.access_token)
        setTimeout(() => {
          setSuccess(true)
        }, 10)
      }
    }).catch((err) => {
      setWarning(true)
    })
  }


  const handleClose = () => {
    setShowLoginModal(false);
  };

  const [userToken, setUserToken] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [warning2, setWarning2] = useState(false);
  const loginKakao = () => {
    window.open('https://kauth.kakao.com/oauth/authorize?client_id='+process.env.REACT_APP_KAKAO_LOGIN_CLIENT_KEY+'&redirect_uri='+process.env.REACT_APP_API_URI+'/v4/auth/login/redirect/&response_type=code', "", "width=300px, height=300")
    window.addEventListener('message', ((e) => {
      if (e.data.type == "login") {
        setUserAccount(e.data.user)
        setUserToken(e.data.access_token)
        setTimeout(() => {
          setSuccess(true)
        }, 10)
      } else if (e.data.type == "reject") {
        setWarning2(true)
      }
    }), false);
  }

  return (
    <>
      { showSignupModal ? (
        <SignupModal open={showSignupModal} setShowSignupModal={setShowSignupModal} />
      ) :
      showFindAccountModal ? (
        <></>
        // <FindAccountModal
        //   open={showFindAccountModal}
        //   setShowModal={setShowFindAccountModal}
        // />
      ) : (
        <>
        <CompleteModal showModal={success} setShowModal={setSuccess} title={"로그인 성공"} description={"즐거운 시간되세요 :)"} onAction={() => {
          setShowLoginModal(false)
          setTimeout(() => {
            dispatch(setLogin(userToken, userAccount))
          }, 10)
        }} />
        <WarningModal showModal={warning2} setShowModal={setWarning2} title={"카카오톡 인증에 실패했습니다."} description={""} action={""} />
        <WarningModal showModal={warning} setShowModal={setWarning} title={"아이디 또는 비밀번호가 일치하지 않습니다"} description={""} action={""}/>
        <Modal
          open={open}
          onClose={handleClose}
          closeOnClick={true}
          disableEnforceFocus={true}
          disableBackdropClick={false} // Disable closing when clicking on the backdrop
          disableEscapeKeyDown={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={style}>
                <img
                  onClick={() => setShowLoginModal(false)}
                  src={IconX}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "22px",
                    right: "22px",
                  }}
                />
                <Box
                  mt={3}
                  mb={6}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <H5>사업가의 독서리뷰</H5>
                </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableContent mb={1}>
                        아이디
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </TableContent>
                      <AppTextField
                        fullWidth
                        name="fullName"
                        placeholder=""
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 18 } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContent mb={1}>
                        비밀번호
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </TableContent>
                      <AppTextField
                        fullWidth
                        name="state"
                        placeholder=""
                        onChange={(e) => {
                          setUserPassword(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 18 } }}
                        hintText="Password"
                        floatingLabelText="Password"
                        type="password"
                      />
                    </Grid>
{/* 
                    <Grid item xs={12}>
                      <TableContent mb={1}>
                        닉네임
                        <span style={{ color: theme.palette.primary.red }}>
                          {" "}
                          *
                        </span>
                      </TableContent>
                      <AppTextField
                        fullWidth
                        name="fullName"
                        placeholder=""
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 18 } }}
                      />
                    </Grid> */}
                    <Grid
                      ml={0}
                      mt={1}
                      container
                      xs={12}
                      flexDirection={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ width: "100%" }}
                      spacing={2}
                    >
                      <Grid item width="100%">
                        <Button
                          // type="submit"
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: 15.5,
                            fontWeight: 500,
                            minWidth: "100%",
                            width: "100%",
                            borderRadius: 2,
                            backgroundColor: "primary.darkBlue",
                            "&:hover": {
                              backgroundColor: "primary.darkBlueHover",
                            },
                          }}
                          onClick={() => {
                            login()
                          }}
                        >
                          로그인
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
              </Card>
            </Grid>
          </Grid>
        </Modal>
        </>
      )}
    </>
  );
}

// const LoginModalButton = () => {
//   const [open, setOpen] = useState(false);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => {
//     console.log("modal close");
//     // setOpen(false);
//   };

//   return (
//     <div>
//       {/* <Button onClick={handleOpen}> */}
//       <H6
//         fontWeight={600}
//         onClick={handleOpen}
//         style={{ cursor: "pointer", whiteSpace: "nowrap" }}
//       >
//         로그인
//       </H6>
//       {/* </Button> */}
//       <CustomModal open={open} onClose={handleClose} />
//     </div>
//   );
// };

// export default LoginModalButton;
