import React, { useState } from "react";
import { Box, Fab, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import insta from "assets/fab-insta.png";
import kakao from "assets/fab-kakao.png";
import naver from "assets/fab-naver.png";
import youtube from "assets/fab-youtube.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { setSetting } from "reducer/sidebarReducer";
import { useDispatch } from "react-redux";

export default function FAB({ marginBottom }) {
  const theme = useTheme();
  const [isFabClicked, setIsFabClicked] = useState(false);
  const setting = useSelector((state) => state.Sidebar.setting);
  const account = useSelector((state) => state.Auth.account);
  const token = useSelector((state) => state.Auth.token);
  const dispatch = useDispatch()

  const OpenQeustionAlert = (id) => {
    const data = window.prompt(id+" 링크 수정", setting?.fab?.[id]);
    if (!data) return;
    const newSetting = { ...setting }
    if (!newSetting.fab) newSetting.fab = {}
    newSetting.fab[id] = data;
    axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      dispatch(setSetting(newSetting))
    })
  }
  if (setting?.channelio) {
    return null
  }
  return (
    <>
      {isFabClicked && (
        <Box
          sx={{
            position: "fixed",
            bottom: 70,
            right: 20,
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
            zIndex: 9999,
            marginBottom: marginBottom ? marginBottom : 0,
          }}
        >
          
          <img
            src={insta}
            alt="icon 1"
            style={{ width: 44 }}
            onClick={() => {
              if (setting?.fab?.instagram) {
                window.open(setting?.fab?.instagram, "_blank");
              }
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              OpenQeustionAlert("instagram");
            }}
          />
          <img
            src={naver}
            alt="icon 2"
            style={{ width: 44, marginBottom: 5 }}
            onContextMenu={(e) => {
              e.preventDefault();
              OpenQeustionAlert("naver");
            }}
            onClick={() => {
              if (setting?.fab?.naver) {
                window.open(setting?.fab?.naver, "_blank");
              }
            }}
          />
          <img
            src={youtube}
            alt="icon 3"
            style={{ width: 44, marginBottom: 5 }}
            onContextMenu={(e) => {
              e.preventDefault();
              OpenQeustionAlert("youtube");
            }}
            onClick={() => {
              if (setting?.fab?.youtube) {
                window.open(setting?.fab?.youtube, "_blank");
              }
            }}
          />
          <img
            src={kakao}
            alt="icon 4"
            style={{ width: 44, marginBottom: 5 }}
            onContextMenu={(e) => {
              e.preventDefault();
              OpenQeustionAlert("kakao");
            }}
            onClick={() => {
              if (setting?.fab?.kakao) {
                window.open(setting?.fab?.kakao, "_blank");
              }
            }}
          />
        </Box>
      )}
      <Fab
        color="primary"
        variant="extended"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          paddingY: 0,
          height: 40,
          backgroundColor: "#024EA2",
          color: "#ffffff",
          fontWeight: 500,
          fontSize: 16,
          marginBottom: marginBottom ? marginBottom : 0,
          zIndex: 20,
          "&:hover": {
            backgroundColor: theme.palette.primary.darkBlueHover,
          },
        }}
        onClick={() => setIsFabClicked(!isFabClicked)}
      >
        상담
        <AddIcon sx={{ ml: 0.5, fontSize: "medium" }} />
      </Fab>
    </>
  );
}
