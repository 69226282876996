import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import { useTheme } from "@emotion/react";
import { PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  useMediaQuery,
  Modal,
} from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBox from "components/flexbox/FlexBox";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import { H5, H6, Small, TableContent, TimerContent, Tiny } from "components/Typography";
import { useFormik } from "formik";
import * as Yup from "yup"; // styled components
import MySelect from "components/MySelect";
import naver from "assets/fab-naver.png";
import TermsModalButton from "./terms-modal";
import { NavLink } from "react-router-dom";
import IconX from "assets/icon-close-modal.svg";
import axios from "axios";
import { useDaumPostcodePopup } from "react-daum-postcode";
import CompleteModal from "./alerts/SuccessModal";
import WarningModal from "./alerts/WarningModal";
import toast from "react-hot-toast";

function SignupModals({ open, setShowSignupModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "44%",
    maxHeight: "90vh", // Set max height for scroll
    overflowY: "auto", // Enable vertical scroll if content overflows
    bgcolor: "background.paper",
    border: "1px solid #E1E1E1",
    // boxShadow: 24,
    p: 4,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0.4em", // Adjust the width as needed
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };

  const handleClose = () => {
    setShowSignupModal(false);
  };

  



  const daum = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js")

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = ''; //추가될 주소
    let localAddress = data.sido + ' ' + data.sigungu; //지역주소(시, 도 + 시, 군, 구)
    if (data.addressType === 'R') { //주소타입이 도로명주소일 경우
      if (data.bname !== '') {
        extraAddress += data.bname; //법정동, 법정리
      }
      if (data.buildingName !== '') { //건물명
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      //지역주소 제외 전체주소 치환
      fullAddress = fullAddress.replace(localAddress, '');

    }
    
    setUserAddress(fullAddress += (extraAddress !== '' ? `(${extraAddress})` : ''))
  }

  const [userType, setUserType] = useState("개인");
  const [userEmail, setUserEmail] = useState("");
  const [userEmailAuth, setUserEmailAuth] = useState(false);
  const [userEmailAuthMessage, setUserEmailAuthMessage] = useState("인증요청");
  const [userEmailAuthCode, setUserEmailAuthCode] = useState("");
  const [userEmailAuthToken, setUserEmailAuthToken] = useState("");
  const [userName, setUserName] = useState("");
  const [userNickName, setUserNickName] = useState("");
  const [userId, setUserId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordCheck, setUserPasswordCheck] = useState("");
  const [userPhoneFirst, setUserPhoneFirst] = useState("010");
  const [userPhoneSecond, setUserPhoneSecond] = useState("");
  const [userPhoneThird, setUserPhoneThird] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userAddressDetail, setUserAddressDetail] = useState("");

  const [userAgreeAll, setUserAgreeAll] = useState(false);
  const [userAgreeTerms, setUserAgreeTerms] = useState(false);
  const [userAgreePrivacy, setUserAgreePrivacy] = useState(false);
  const [userAgreeShopping, setUserAgreeShopping] = useState(false);
  const [userAgreeSms, setUserAgreeSms] = useState(false);
  const [userAgreeEmail, setUserAgreeEmail] = useState(false);
  const [userAgreePrivacy3, setUserAgreePrivacy3] = useState(false);

  const [authTrigger, setAuthTrigger] = useState(false);

  const registerUser = () => {
    const alert = (msg) => {
      toast.error(msg)
    }
    if (!userEmail) {
      alert("이메일을 입력해주세요.")
      return
    }
    if (!userEmailAuth) {
      alert("이메일 인증을 해주세요.")
      return
    }

    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    if (!(regex.test(userEmail))) {
      alert("올바른 네이버 이메일을 입력해주세요.")
      return
    }
    if (!userName) {
      alert("이름을 입력해주세요.")
      return
    }
    if (userName.length < 2 || userName.length > 9) {
      alert("이름은 2자 이상, 9자 이하로 입력해주세요.")
      return
    }
    if (!userNickName) {
      alert("닉네임를 입력해주세요.")
      return
    }
    if (userNickName.length < 1) {
      alert("닉네임은 2자이상 입력해주세요.")
      return
    }
    if (!userPassword && userPassword.length < 1) {
      alert("비밀번호를 입력해주세요.")
      return
    }

    if (userPassword.length < 8) {
      alert("비밀번호는 8자 이상 입력해주세요.")
      return
    }

    if (!userPasswordCheck) {
      alert("비밀번호 확인을 입력해주세요.")
      return
    }
    if (userPassword !== userPasswordCheck) {
      alert("비밀번호가 일치하지 않습니다.")
      return
    }
    if (!userPhoneSecond) {
      alert("휴대전화를 입력해주세요.")
      return
    }
    if (!userAgreeAll) {
      if (!userAgreeTerms) {
        alert("이용약관 동의를 해주세요.")
        return
      }
      if (!userAgreePrivacy) {
        alert("개인정보처리방침 동의를 해주세요.")
        return
      }
      if (!userAgreeShopping) {
        alert("쇼핑정보 수신 동의를 해주세요.")
        return
      }
      if (!userAgreeSms) {
        alert("SMS 수신 동의를 해주세요.")
        return
      }
      if (!userAgreeEmail) {
        alert("이메일 수신 동의를 해주세요.")
        return
      }
      if (!userAgreePrivacy3) {
        alert("개인정보 제3자 제공 동의를 해주세요.")
        return
      }
    }

    axios.post(process.env.REACT_APP_API_URI+"/v4/auth/login/register", {
      nick_name: userNickName,
      password: userPassword,
      name: userName,
      email: userEmail,
      phone: userPhoneSecond,
    }, {
      headers: {
        authorization: userEmailAuthToken
      }
    }).then((res) => {
      if (res.data.success) {
        setSuccessJoin(true)
      }
    }).catch((err) => {
      alert(err.response.data.message)
    })
  }

  const timeEnd = () => {
    setAuthTrigger(false)
    alert("인증 시간이 만료되었습니다. 다시 인증해주세요.")
  }

  const [sendEmail, setSendEmail] = useState(false)
  const [successEmail, setSuccessEmail] = useState(false)
  const [successJoin, setSuccessJoin] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailAuthError, setEmailAuthError] = useState(false)

  return (
    <>
    <CompleteModal showModal={sendEmail} setShowModal={setSendEmail} title={"인증번호가 전송되었습니다."} description={""} action={""}/>
    <CompleteModal showModal={successEmail} setShowModal={setSuccessEmail} title={"인증이 완료되었습니다."} description={""} action={""}/>
    <CompleteModal showModal={successJoin} setShowModal={setSuccessJoin} title={"회원가입 성공"} description={"함께 해주셔서 감사합니다"} action={"window.location.reload()"}/>
    <WarningModal showModal={emailError} setShowModal={setEmailError} title={"이메일 형식이 올바르지 않습니다."} description={"올바른 이메일을 입력해주세요."} action={""}/>
    <WarningModal showModal={emailAuthError} setShowModal={setEmailAuthError} title={"인증번호가 일치하지 않습니다."} description={""} action={""}/>

    <Modal
      open={open}
      onClose={handleClose}
      closeOnClick={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(5px)" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={style}>
            <img
              onClick={() => setShowSignupModal(false)}
              src={IconX}
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                position: "absolute",
                top: "22px",
                right: "22px",
              }}
            />
            <Box
              mt={3}
              mb={6}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <H5>회원 가입</H5>
            </Box>

            <Grid container spacing={2}>

              <Grid item sm={6} xs={12}>
                <TableContent mb={1}>
                  이메일
                  <span style={{ color: theme.palette.primary.red }}> *</span>
                </TableContent>
                <AppTextField
                  fullWidth
                  placeholder="이메일을 입력해 주세요"
                  sx={{
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontWeight: 300,
                      fontSize: 15.5,
                      color: "#B5B7C0",
                    },
                  }}
                  inputProps={{ style: { height: 18 } }}
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value.toLowerCase())
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                {!isMobile && (
                  <TableContent mb={1}>
                    <span style={{ color: "#ffffff" }}> *</span>
                  </TableContent>
                )}
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <FlexRowAlign
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <AppTextField
                      fullWidth
                      placeholder="인증번호를 입력해 주세요"
                      sx={{
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontWeight: 300,
                          fontSize: 15.5,
                          color: "#B5B7C0",
                        },
                      }}
                      inputProps={{ style: { height: 18 } }}
                      onChange={(e) => {
                        setUserEmailAuthCode(e.target.value)
                      }}
                    />
                    <TimerContent trigger={authTrigger} time="3:00" endEvent={timeEnd}>3:00</TimerContent>
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{
                        fontSize: 14.5,
                        fontWeight: 400,
                        width: 180,
                        borderRadius: 1.4,
                      }}
                      onClick={() => {
                        if (userEmailAuthMessage == "인증요청") {
                          const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
                          const naver = /@naver\.com$/
                          if (regex.test(userEmail)) {
                            axios.get(process.env.REACT_APP_API_URI+"/v4/auth/login/verify-email?email=" + userEmail).then((res) => {
                              if (res.data.success == false) {
                                toast.error(res.data.message)
                                return
                              }
                              setAuthTrigger(true)
                              setUserEmailAuthMessage("인증확인")
                              setSendEmail(true)
                            }).catch((err) => {
                              toast.error(err.response.data.message)
                            })
                          } else {
                            setEmailError(true)
                          }
                        } else if (userEmailAuthMessage == "인증확인") {
                          axios.get(process.env.REACT_APP_API_URI+"/v4/auth/login/verify-code?email=" + userEmail + "&code=" + userEmailAuthCode).then((res) => {
                            if (res.data.success == false) {
                              setEmailAuthError(true)
                              return
                            } else {
                              setAuthTrigger(false)
                              setUserEmailAuthToken(res.data.data.verify_token)
                              setUserEmailAuth(true)
                              setUserEmailAuthMessage("인증완료")
                              setSuccessEmail(true)
                            }
                          }).catch((err) => {
                            setEmailAuthError(true)
                          })
                        }
                      }}
                    >
                      {userEmailAuthMessage}
                    </Button>
                  </FlexRowAlign>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TableContent mb={1}>
                  이름
                  <span style={{ color: theme.palette.primary.red }}> *</span>
                </TableContent>
                <AppTextField
                  fullWidth
                  placeholder="이름을 입력해 주세요"
                  sx={{
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontWeight: 300,
                      fontSize: 15.5,
                      color: "#B5B7C0",
                    },
                  }}
                  inputProps={{ style: { height: 18 } }}
                  helperText={"이름은 2자 이상 입력해주세요."}
                  onChange={(e) => {
                    setUserName(e.target.value)
                  }}
                  error={userName.length < 2}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TableContent mb={1}>
                  닉네임
                  <span style={{ color: theme.palette.primary.red }}> *</span>
                </TableContent>
                <AppTextField
                  fullWidth
                  placeholder="닉네임을 입력해 주세요"
                  sx={{
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontWeight: 300,
                      fontSize: 15.5,
                      color: "#B5B7C0",
                    },
                  }}
                  inputProps={{ style: { height: 18 } }}
                  helperText={"2자 이상, 20자 이하로 입력해주세요."}
                  error={userNickName.length < 1 || userNickName.length > 20}
                  onChange={(e) => {
                    setUserNickName(e.target.value)
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TableContent mb={1}>
                  비밀번호
                  <span style={{ color: theme.palette.primary.red }}> *</span>
                </TableContent>
                <AppTextField
                  fullWidth
                  placeholder=""
                  sx={{
                    type: "password",
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontWeight: 300,
                      fontSize: 15.5,
                      color: "#B5B7C0",
                    },
                  }}
                  inputProps={{ style: { height: 18 } }}
                  onChange={(e) => {
                    setUserPassword(e.target.value)
                  }}
                  hintText="Password"
                  floatingLabelText="Password"
                  type="password"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TableContent mb={1}>
                  비밀번호 확인
                  <span style={{ color: theme.palette.primary.red }}> *</span>
                </TableContent>
                <AppTextField
                  fullWidth
                  placeholder="비밀번호를 한번 더 입력해 주세요"
                  sx={{
                    "& .MuiOutlinedInput-input::placeholder": {
                      fontWeight: 300,
                      fontSize: 15.5,
                      color: "#B5B7C0",
                    },
                  }}
                  inputProps={{ style: { height: 18 } }}
                  onChange={(e) => {
                    setUserPasswordCheck(e.target.value)
                  }}
                  hintText="Password"
                  floatingLabelText="Password"
                  type="password"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TableContent mb={1}>
                  휴대전화
                  <span style={{ color: theme.palette.primary.red }}> *</span>
                </TableContent>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <FlexRowAlign gap={2}>
                    <AppTextField
                      fullWidth
                      name="email"
                      placeholder=""
                      sx={{
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontWeight: 300,
                          fontSize: 15.5,
                          color: "#B5B7C0",
                        },
                      }}
                      inputProps={{ style: { height: 18 } }}
                      value={userPhoneSecond}
                      onChange={(e) => {
                        let val = e.target.value.replace(/\D/g, '')
                        if (val.startsWith("0")) {
                          val = "0" + val.slice(1,11)
                        } else {
                          val = val.slice(0, 11)
                        }
                        setUserPhoneSecond(val)
                      }}
                    />
                  </FlexRowAlign>
                </Box>
              </Grid>
                {/* <Grid item sm={6} xs={12}>
                  <TableContent mb={1}>주소</TableContent>
                  <FlexRowAlign gap={2}>
                    <AppTextField
                      fullWidth
                      name="address"
                      placeholder="주소를 검색해 주세요"
                      sx={{
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontWeight: 300,
                          fontSize: 15.5,
                          color: "#B5B7C0",
                        },
                      }}
                      inputProps={{ style: { height: 18 } }}
                      value={userAddress}
                    />
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{
                        fontSize: 16.5,
                        fontWeight: 400,
                        width: 100,
                        borderRadius: 2,
                      }}
                      onClick={() => {
                        daum({ onComplete: handleComplete })
                      }}
                    >
                      검색
                    </Button>
                  </FlexRowAlign>
                </Grid>

                <Grid item xs={12}>
                  <TableContent mb={1}>상세 주소</TableContent>
                  <FlexRowAlign gap={2}>
                    <AppTextField
                      fullWidth
                      name="address"
                      placeholder="상세 주소를 입력해주세요."
                      sx={{
                        "& .MuiOutlinedInput-input::placeholder": {
                          fontWeight: 300,
                          fontSize: 15.5,
                          color: "#B5B7C0",
                        },
                      }}
                      inputProps={{ style: { height: 18 } }}
                      value={userAddressDetail}
                      onChange={(e) => {
                        setUserAddressDetail(e.target.value)
                      }}
                    />
                  </FlexRowAlign>
                </Grid> */}
              <Divider
                style={{
                  width: "100%",
                  marginTop: "50px",
                }}
              />

            <Grid item xs={12}>
              <FlexBox
                gap={1}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreeAll} onChange={
                    () => {
                      if (userAgreeAll) {
                        setUserAgreeAll(false)
                        setUserAgreeTerms(false)
                        setUserAgreePrivacy(false)
                        setUserAgreeShopping(false)
                        setUserAgreeSms(false)
                        setUserAgreeEmail(false)
                        setUserAgreePrivacy3(false)
                      } else {
                        setUserAgreeAll(true)
                        setUserAgreeTerms(true)
                        setUserAgreePrivacy(true)
                        setUserAgreeShopping(true)
                        setUserAgreeSms(true)
                        setUserAgreeEmail(true)
                        setUserAgreePrivacy3(true)
                      }
                    }
                  } />
                  <TableContent
                    ml={1}
                    style={{
                      color: theme.palette.primary.dark,
                      whiteSpace: "normal",
                    }}
                  >
                    [전체 동의] 이용약관 및 개인정보수집 및 이용, 개인정보 제3자 동의, 쇼핑정보 수신에 모두 동의합니다.
                  </TableContent>
                </Box>
              </FlexBox>
            </Grid>

            <Grid item xs={12}>
              <FlexBox
                gap={1}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreeTerms} onChange={() => {
                    if (userAgreeTerms) {
                      setUserAgreeTerms(false)
                      setUserAgreeAll(false)
                    } else {
                      setUserAgreeTerms(true)
                      if (userAgreePrivacy && userAgreeShopping && userAgreeSms && userAgreeEmail && userAgreePrivacy3) {
                        setUserAgreeAll(true)
                      }
                    }
                  }} />
                  <TableContent ml={1}>
                    이용약관 동의 (필수){" "}
                    <span style={{ color: theme.palette.primary.red }}>
                      *
                    </span>
                  </TableContent>
                </Box>
                <Box
                  ml={isMobile ? 3.5 : 0}
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <TermsModalButton type={"term"} />
                </Box>
              </FlexBox>
            </Grid>

            <Grid item xs={12}>
              <FlexBox
                gap={1}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreePrivacy} onChange={() => {
                    if (userAgreePrivacy) {
                      setUserAgreePrivacy(false)
                      setUserAgreeAll(false)
                    } else {
                      setUserAgreePrivacy(true)
                      if (userAgreeTerms && userAgreeShopping && userAgreeSms && userAgreeEmail && userAgreePrivacy3) {
                        setUserAgreeAll(true)
                      }
                    }
                  }} />
                  <TableContent ml={1}>
                    개인정보처리방침 동의 (필수)
                    <span style={{ color: theme.palette.primary.red }}>
                      *
                    </span>
                  </TableContent>
                </Box>
                <Box
                  ml={isMobile ? 3.5 : 0}
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <TermsModalButton type={"pri"} />
                </Box>
              </FlexBox>
            </Grid>

            <Grid item xs={12}>
              <FlexBox
                gap={1}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreePrivacy} onChange={() => {
                    if (userAgreePrivacy) {
                      setUserAgreePrivacy3(false)
                      setUserAgreeAll(false)
                    } else {
                      setUserAgreePrivacy3(true)
                      if (userAgreeTerms && userAgreeShopping && userAgreeSms && userAgreeEmail && userAgreePrivacy) {
                        setUserAgreeAll(true)
                      }
                    }
                  }} />
                  <TableContent ml={1}>
                    개인정보 제3자 제공 동의 (필수)
                    <span style={{ color: theme.palette.primary.red }}>
                      *
                    </span>
                  </TableContent>
                </Box>
                <Box
                  ml={isMobile ? 3.5 : 0}
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <TermsModalButton />
                </Box>
              </FlexBox>
            </Grid>

            <Grid item xs={12}>
              <FlexBox
                gap={1}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreeShopping} onChange={() => {
                    if (userAgreeShopping) {
                      setUserAgreeShopping(false)
                      setUserAgreeAll(false)
                    } else {
                      setUserAgreeShopping(true)
                      if (userAgreeTerms && userAgreePrivacy && userAgreeSms && userAgreeEmail && userAgreePrivacy3) {
                        setUserAgreeAll(true)
                      }
                    }
                  }} />
                  <TableContent ml={1}>
                    쇼핑정보 수신 동의 (필수)
                  </TableContent>
                </Box>
                <Box
                  ml={isMobile ? 3.5 : 0}
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <TermsModalButton type={"shop"} />
                </Box>
              </FlexBox>
            </Grid>

            <Grid item xs={12}>
              <FlexBox
                ml={3.5}
                gap={1}
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  // justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreeSms} onChange={() => {
                    if (userAgreeSms) {
                      setUserAgreeSms(false)
                      setUserAgreeAll(false)
                    } else {
                      setUserAgreeSms(true)
                      if (userAgreeTerms && userAgreePrivacy && userAgreeShopping && userAgreeEmail && userAgreePrivacy3) {
                        setUserAgreeAll(true)
                      }
                    }
                  }} />
                  <TableContent ml={1}>SMS 수신 동의 (필수)</TableContent>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <AppCheckBox  checked={userAgreeEmail} onChange={() => {
                    if (userAgreeEmail) {
                      setUserAgreeEmail(false)
                      setUserAgreeAll(false)
                    } else {
                      setUserAgreeEmail(true)
                      if (userAgreeTerms && userAgreePrivacy && userAgreeShopping && userAgreeSms && userAgreePrivacy3) {
                        setUserAgreeAll(true)
                      }
                    }
                  }} />
                  <TableContent ml={1}>
                    이메일 수신 동의 (필수)
                  </TableContent>
                </Box>
              </FlexBox>
            </Grid>

              <Divider
                style={{
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              />

              <Grid
                ml={0}
                mt={1}
                container
                xs={12}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Grid item width={isMobile ? "100%" : 250}>
                  <Button
                    // type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: 15.5,
                      fontWeight: 500,
                      borderRadius: 2,
                      minWidth: isMobile ? "100%" : 240,
                      width: isMobile ? "100%" : 240,
                      borderRadius: 2,
                      backgroundColor: "primary.darkBlue",
                      "&:hover": {
                        backgroundColor: "primary.darkBlueHover",
                      },
                    }}
                    onClick={registerUser}
                  >
                    회원가입
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Modal>
    </>
  );
}


export default SignupModals;
