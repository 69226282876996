import { Fragment, useState } from "react";
import { Outlet, useLocation } from "react-router"; // Import useLocation hook
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import Footer from "components/Footer";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import BottomBar from "components/BottomBar";
import FAB from "components/FAB";
import DashboardHeaderNew from "layouts/layout-parts/DashboardHeader-new";

const WrtnLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation(); // Get the current location

  const [showSideBar, setShowSideBar] = useState(true);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
<FAB marginBottom={"60px"} />
  const customStyle = {
    width: `calc(100% - ${showSideBar ? "260px" : "0px"})`,
    marginLeft: showSideBar ? "260px" : "0px",
  };

  // Check if the current route is '/naver/wrtn'
  const hideFooter = location.pathname === "/naver/wrtn";

  {isMobile && (
    <BottomBar setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)} showMobileSideBar={showMobileSideBar}/>
  )}
  return (
    <Fragment>
      
    <DashboardHeaderNew
      setShowSideBar={() => setShowSideBar((state) => !state)}
      setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
    />

      {/* Conditionally render children or Outlet based on route */}
      {hideFooter || window.location.hostname == "booksense.club" ? (
        children || <Outlet />
      ) : (
        <Fragment>
          {children || <Outlet />}
          {/* <Footer showSideBar={showSideBar} /> */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default WrtnLayout;
