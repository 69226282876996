import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  useMediaQuery,
  Modal,
} from "@mui/material";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import AppTextField from "components/input-fields/AppTextField";
import {
  H4,
  H5,
  H6,
  H7,
  Small,
  TableContent,
  Tiny,
} from "components/Typography";
import IconX from "assets/icon-close-modal.svg";
import SurveyCompleteModal from "page/modal/survey-complete-modal";
export default function QModal({ open, setShowModal, txt, setTxt, onAction, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "48%",
    maxHeight: "90vh",
    overflowY: "auto",
    // borderRadius: 4,
    // border: "1px solid #E1E1E1",
    // p: 4,
    borderWidth: 0,
    scrollbarWidth: "thin", // Firefox
    "&::-webkit-scrollbar": {
      width: "0", // Adjust the width as needed
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide scrollbar thumb
    },
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = () => {
    if(onAction)onAction(txt);
    setShowModal(false);
  };

  return (
    <>
      {showCompleteModal ? (
        <SurveyCompleteModal
          showModal={showCompleteModal}
          setShowModal={setShowCompleteModal}
          onExit={() => setShowModal(false)}
        />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          closeOnClick={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <Grid container sx={style}>
            <Grid item xs={12}>
              <Card>
                <img
                  onClick={() => setShowModal(false)}
                  src={IconX}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "22px",
                    right: "22px",
                  }}
                />
                  <Grid container spacing={3} py={4} px={isMobile ? 2 : 4}>
                    <Grid item xs={12}>
                      <FlexRowAlign
                        sx={{ justifyContent: "space-between", width: "100%" }}
                      >
                        <H6 mb={1}>
                          {title ? title : "누르면 이동할 링크를 입력해주세요. (없을시 입력 X)"}
                          <span style={{ color: theme.palette.primary.red }}>
                            {" "}
                            *
                          </span>
                        </H6>
                      </FlexRowAlign>
                      <AppTextField
                        fullWidth
                        name="name"
                        placeholder={title ? title : "링크을 입력해 주세요"}
                        value={txt}
                        onChange={(e) => {
                          setTxt(e.target.value)
                        }}
                        sx={{
                          backgroundColor:
                            theme.palette.primary.lightBlueBackground,
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontWeight: 300,
                            fontSize: 15.5,
                            color: "#B5B7C0",
                          },
                        }}
                        inputProps={{ style: { height: 18 } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    ml={0}
                    mt={0}
                    mb={2}
                    container
                    xs={12}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Grid item width={isMobile ? "100%" : 250}>
                      <Button
                        // type="submit"
                        onClick={() => onSubmit()}
                        variant="contained"
                        size="small"
                        sx={{
                          fontSize: 15.5,
                          fontWeight: 500,
                          minWidth: isMobile ? "100%" : 240,
                          width: isMobile ? "100%" : 240,
                          borderRadius: 2,
                          backgroundColor: "primary.darkBlue",
                          "&:hover": {
                            backgroundColor: "primary.darkBlueHover",
                          },
                        }}
                      >
                        확인
                      </Button>
                    </Grid>
                  </Grid>
              </Card>
            </Grid>
            </Grid>
        </Modal>
      )}
    </>
  );
}
