import dashboard from "assets/sidemenu/dashboard.png";
import briefcase from "assets/sidemenu/briefcase.png";
import calendar from "assets/sidemenu/calendar.png";
import chat from "assets/sidemenu/chat.png";
import coin from "assets/sidemenu/coin.png";
import contents from "assets/sidemenu/contents.png";
import find from "assets/sidemenu/find.png";
import folder from "assets/sidemenu/folder.png";
import graduation from "assets/sidemenu/graduation.png";
import lightbulb from "assets/sidemenu/lightbulb.png";
import medal from "assets/sidemenu/medal.png";
import namecard from "assets/sidemenu/namecard.png";
import paper from "assets/sidemenu/paper.png";
import pen from "assets/sidemenu/pen.png";
import speaker from "assets/sidemenu/speaker.png";
import stamp from "assets/sidemenu/stamp.png";
import triangle from "assets/sidemenu/triangle.png";
import user from "assets/sidemenu/user.png";
import video from "assets/sidemenu/video.png";
import graph from "assets/sidemenu/graph.png";
import calculator from "assets/sidemenu/calculator.png";
import worker from "assets/sidemenu/worker.png";

import dashboardSelected from "assets/sidemenu/dashboard-selected.png";
import briefcaseSelected from "assets/sidemenu/briefcase-selected.png";
import calendarSelected from "assets/sidemenu/calendar-selected.png";
import chatSelected from "assets/sidemenu/chat-selected.png";
import coinSelected from "assets/sidemenu/coin-selected.png";
import contentsSelected from "assets/sidemenu/contents-selected.png";
import findSelected from "assets/sidemenu/find-selected.png";
import folderSelected from "assets/sidemenu/folder-selected.png";
import graduationSelected from "assets/sidemenu/graduation-selected.png";
import lightbulbSelected from "assets/sidemenu/lightbulb-selected.png";
import medalSelected from "assets/sidemenu/medal-selected.png";
import namecardSelected from "assets/sidemenu/namecard-selected.png";
import paperSelected from "assets/sidemenu/paper-selected.png";
import penSelected from "assets/sidemenu/pen-selected.png";
import speakerSelected from "assets/sidemenu/speaker-selected.png";
import stampSelected from "assets/sidemenu/stamp-selected.png";
import triangleSelected from "assets/sidemenu/triangle-selected.png";
import userSelected from "assets/sidemenu/user-selected.png";
import videoSelected from "assets/sidemenu/video-selected.png";
import graphSelected from "assets/sidemenu/graph-selected.png";
import calculatorSelected from "assets/sidemenu/calculator-selected.png";
import workerSelected from "assets/sidemenu/worker-selected.png";

const sideMenuList1 = [
  {
    "title": "divider"
  },
  {
    "title": "공지사항",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fba11d0d7676cfac842e295609be06b1f.png",
    "path": "/board/notice",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6ffa39e7da062f80bf78ba7fc4c2e0f8bbcbe3bb7e470c3ed22b6e724a39715252.png"
  },
  {
    "title": "divider"
  },
  {
    "title": "지원사업 게시판",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dba45b317caaeb1398a525bc4a1edab64.png",
    "path": "/bizanno/bizinfo",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347df6aac780c8d812250aa274dae2e2e67b41eed2fdc4cdd3b673a429e1679496ef.png",
    "expanded": true,
    "children": [
      {
        "name": "기업마당",
        "path": "/bizanno/bizinfo"
      },
      {
        "name": "KSTARTUP",
        "path": "/bizanno/kstartup"
      }
    ]
  },
  {
    "title": "정책자금 찾기",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263cb8102a055272246cb2101c6f7f77fc872d9d19bfdb4533a6a7a6b43ac1b1cf49.png",
    "path": "/",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263cb8102a055272246cb2101c6f7f77fc87f1f4ac9b246d9e35a278dbc38cb24dadd1684d24004ce585025a35e73396099e.png"
  },
  {
    "title": "동영상 강의",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dfa6a8ba14b45f6cccc3493de44b56bbab3111220bfe58c5d07ab8f766a4f73c4.png",
    "path": "/course",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d3954dc53f36a64b52b83edbf44a1406882bcfb1e9b19e5c8ab3821e6ec431f63.png",
    "children": [
      {
        "name": "동영상 강의 (게시판)",
        "path": "/lecture/course"
      }
    ]
  },
  {
    "title": "사업지식 게시판",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dfa6a8ba14b45f6cccc3493de44b56bbab3111220bfe58c5d07ab8f766a4f73c4.png",
    "path": "/board/tips",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d3954dc53f36a64b52b83edbf44a1406882bcfb1e9b19e5c8ab3821e6ec431f63.png"
  },
  {
    "title": "기업업무 양식",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dd69f420b2c25ce5b4f94d453e334ff9f.png",
    "path": "/board/business",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347de66d296e63ff7e665875170e7269e06b8c255484b0dc49e274b4bc46591ec595.png"
  },
  {
    "title": "자유게시판",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d26d571566343370423717090ab6d281e.png",
    "path": "/board/forum",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d16fe54b22d5050137e76b0f17c586ab59627cae03991f979123941633209e262.png"
  },
  {
    "title": "divider"
  },
  {
    "title": "슈가 AI",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fa15ed508ba94546554f9d964b4db6883.png",
    "path": "/multiai/1",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6f8b742b20fb3db16f63acebd49461ad045c46d46b60ff5d5ee0a547c7d0ec7302.png",
    "children": [
      {
        "name": "멀티 AI (5)",
        "path": "/multiai/5"
      },
      {
        "name": "멀티 AI (15)",
        "path": "/multiai/15"
      }
    ]
  },
  {
    "login": true,
    "type": "매니저",
    "title": "업무 소통",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d37a0f845be10290e0778d548361c3e208274aef0cb7a578eec77ac0d2bfc1ef8.png",
    "path": "/board/work",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d0e059dee096d6bc3e33736c5a20f432245ebb3f8503fa4db0e8e12bd3a607ca3.png",
    "children": [
      {
        "name": "의견 및 소통",
        "path": "/board/w-opinion"
      },
      {
        "name": "블로그",
        "path": "/board/w-blog"
      },
      {
        "name": "유튜브",
        "path": "/board/w-youtube"
      },
      {
        "name": "디자인",
        "path": "/board/w-design"
      },
      {
        "name": "교육자료",
        "path": "/board/w-edu"
      },
      {
        "name": "자료실",
        "path": "/board/w-data"
      }
    ]
  },
  {
    "login": true,
    "title": "설정",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d9467f387b9402dd1530683b68e1e3578.png",
    "path": "/settings/profile",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d1975a1462d6803f000d7d03692f37b10f585effdcff4f883c032b2e77686da7c.png",
    "children": [
      {
        "name": "내 계정",
        "path": "/settings/profile"
      },
      {
        "name": "명함 관리",
        "path": "/settings/namecard"
      }
    ]
  },
  {
    "login": true,
    "partner_type": "FC",
    "title": "영업 현황",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fdc402958359f9b3511aa6adf57dc86f2.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fc37d6be19c1581da2031121114e3e64b5aeb60a8e8819894d893bb6276903a0c.png",
    "path": "/progress/report",
    "expanded": true,
    "children": [
      {
        "name": "내 영업 현황",
        "path": "/progress/report",
        "partner_type": ["FC", "팀장", "영업이사"]
      },
      {
        "name": "팀 영업 현황",
        "path": "/progress/team",
        "partner_type": ["팀장"]
      },
      {
        "name": "기업 영업 현황 요약",
        "path": "/progress/company_summary",
        "partner_type": ["영업이사", "마스터"]
      },
      {
        "name": "기업 상세 영업 현황",
        "path": "/progress/company",
        "partner_type": ["영업이사", "마스터"]
      },
      {
        "name": "직속 FC 영업 현황",
        "path": "/progress/fc",
        "partner_type": ["영업이사"]
      }
    ]
  },
  {
    "login": true,
    "type": "정직원 매니저",
    "title": "관리 페이지",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d5d6e631a5cdb7e282391543e796edb1aeeb86bbe2fd594ddb7cde13a155f52a3.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dc57c0f27dd9dd41e4f504758a7bc49ba2488f7a3946c253896a46c82a437f9d3.png",
    "path": "/admin/user-list",
    "expanded": true,
    "children": [
      {
        "name": "사용자 관리",
        "path": "/admin/user-list"
      },
      {
        "name": "배너 관리",
        "path": "/admin/banner-editor"
      }
    ]
  },
  {
    "login": true,
    "type": "개인",
    "cretop": true,
    "title": "기업DB",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d5d6e631a5cdb7e282391543e796edb1aeeb86bbe2fd594ddb7cde13a155f52a3.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dc57c0f27dd9dd41e4f504758a7bc49ba2488f7a3946c253896a46c82a437f9d3.png",
    "path": "/cretop"
  },
  {
    "login": true,
    "type": "매니저",
    "title": "기업 검색",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d5d6e631a5cdb7e282391543e796edb1aeeb86bbe2fd594ddb7cde13a155f52a3.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dc57c0f27dd9dd41e4f504758a7bc49ba2488f7a3946c253896a46c82a437f9d3.png",
    "path": "/cretop",
    "expanded": true,
    "children": [
      {
        "name": "기업 보고서 생성",
        "path": "/cretop"
      },
      {
        "name": "기업DB 목록",
        "path": "/cretop/db"
      }
    ]
  }
];

const sideMenuList2 = [
  {
    "title": "divider"
  },
  {
    "title": "공지사항",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fba11d0d7676cfac842e295609be06b1f.png",
    "path": "/board/notice",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6ffa39e7da062f80bf78ba7fc4c2e0f8bbcbe3bb7e470c3ed22b6e724a39715252.png"
  },
  {
    "title": "divider"
  },
  {
    "title": "지원사업 게시판",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dba45b317caaeb1398a525bc4a1edab64.png",
    "path": "/bizanno/bizinfo",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347df6aac780c8d812250aa274dae2e2e67b41eed2fdc4cdd3b673a429e1679496ef.png",
    "expanded": true,
    "children": [
      {
        "name": "기업마당",
        "path": "/bizanno/bizinfo"
      },
      {
        "name": "KSTARTUP",
        "path": "/bizanno/kstartup"
      }
    ]
  },
  {
    "title": "정책자금 찾기",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263cb8102a055272246cb2101c6f7f77fc872d9d19bfdb4533a6a7a6b43ac1b1cf49.png",
    "path": "/",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263cb8102a055272246cb2101c6f7f77fc87f1f4ac9b246d9e35a278dbc38cb24dadd1684d24004ce585025a35e73396099e.png"
  },
  {
    "title": "동영상 강의",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dfa6a8ba14b45f6cccc3493de44b56bbab3111220bfe58c5d07ab8f766a4f73c4.png",
    "path": "/course",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d3954dc53f36a64b52b83edbf44a1406882bcfb1e9b19e5c8ab3821e6ec431f63.png",
    "children": [
      {
        "name": "동영상 강의 (게시판)",
        "path": "/lecture/course"
      }
    ]
  },
  {
    "title": "사업지식 게시판",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dfa6a8ba14b45f6cccc3493de44b56bbab3111220bfe58c5d07ab8f766a4f73c4.png",
    "path": "/board/tips",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d3954dc53f36a64b52b83edbf44a1406882bcfb1e9b19e5c8ab3821e6ec431f63.png"
  },
  {
    "title": "기업업무 양식",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dd69f420b2c25ce5b4f94d453e334ff9f.png",
    "path": "/board/business",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347de66d296e63ff7e665875170e7269e06b8c255484b0dc49e274b4bc46591ec595.png"
  },
  {
    "title": "자유게시판",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d26d571566343370423717090ab6d281e.png",
    "path": "/board/forum",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d16fe54b22d5050137e76b0f17c586ab59627cae03991f979123941633209e262.png"
  },
  {
    "title": "divider"
  },
  {
    "title": "슈가 AI",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fa15ed508ba94546554f9d964b4db6883.png",
    "path": "/multiai/1",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6f8b742b20fb3db16f63acebd49461ad045c46d46b60ff5d5ee0a547c7d0ec7302.png",
    "children": [
      {
        "name": "멀티 AI (5)",
        "path": "/multiai/5"
      },
      {
        "name": "멀티 AI (15)",
        "path": "/multiai/15"
      }
    ]
  },
  {
    "login": true,
    "type": "매니저",
    "title": "업무 소통",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d37a0f845be10290e0778d548361c3e208274aef0cb7a578eec77ac0d2bfc1ef8.png",
    "path": "/board/work",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d0e059dee096d6bc3e33736c5a20f432245ebb3f8503fa4db0e8e12bd3a607ca3.png",
    "children": [
      {
        "name": "의견 및 소통",
        "path": "/board/w-opinion"
      },
      {
        "name": "블로그",
        "path": "/board/w-blog"
      },
      {
        "name": "유튜브",
        "path": "/board/w-youtube"
      },
      {
        "name": "디자인",
        "path": "/board/w-design"
      },
      {
        "name": "교육자료",
        "path": "/board/w-edu"
      },
      {
        "name": "자료실",
        "path": "/board/w-data"
      }
    ]
  },
  {
    "login": true,
    "title": "설정",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d9467f387b9402dd1530683b68e1e3578.png",
    "path": "/settings/profile",
    "expanded": true,
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d1975a1462d6803f000d7d03692f37b10f585effdcff4f883c032b2e77686da7c.png",
    "children": [
      {
        "name": "내 계정",
        "path": "/settings/profile"
      },
      {
        "name": "명함 관리",
        "path": "/settings/namecard"
      }
    ]
  },
  {
    "login": true,
    "partner_type": "FC",
    "title": "영업 현황",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fdc402958359f9b3511aa6adf57dc86f2.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c6f7f3e9869a2f9447adad8bc8b4d7c6fc37d6be19c1581da2031121114e3e64b5aeb60a8e8819894d893bb6276903a0c.png",
    "path": "/progress/report",
    "expanded": true,
    "children": [
      {
        "name": "내 영업 현황",
        "path": "/progress/report",
        "partner_type": ["FC", "팀장", "영업이사"]
      },
      {
        "name": "팀 영업 현황",
        "path": "/progress/team",
        "partner_type": ["팀장"]
      },
      {
        "name": "기업 영업 현황 요약",
        "path": "/progress/company_summary",
        "partner_type": ["영업이사", "마스터"]
      },
      {
        "name": "기업 상세 영업 현황",
        "path": "/progress/company",
        "partner_type": ["영업이사", "마스터"]
      },
      {
        "name": "직속 FC 영업 현황",
        "path": "/progress/fc",
        "partner_type": ["영업이사"]
      }
    ]
  },
  {
    "login": true,
    "type": "정직원 매니저",
    "title": "관리 페이지",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d5d6e631a5cdb7e282391543e796edb1aeeb86bbe2fd594ddb7cde13a155f52a3.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dc57c0f27dd9dd41e4f504758a7bc49ba2488f7a3946c253896a46c82a437f9d3.png",
    "path": "/admin/user-list",
    "expanded": true,
    "children": [
      {
        "name": "사용자 관리",
        "path": "/admin/user-list"
      },
      {
        "name": "배너 관리",
        "path": "/admin/banner-editor"
      }
    ]
  },
  {
    "login": true,
    "type": "개인",
    "cretop": true,
    "title": "기업DB",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d5d6e631a5cdb7e282391543e796edb1aeeb86bbe2fd594ddb7cde13a155f52a3.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dc57c0f27dd9dd41e4f504758a7bc49ba2488f7a3946c253896a46c82a437f9d3.png",
    "path": "/cretop"
  },
  {
    "login": true,
    "type": "매니저",
    "title": "기업 검색",
    "icon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347d5d6e631a5cdb7e282391543e796edb1aeeb86bbe2fd594ddb7cde13a155f52a3.png",
    "selectedIcon": "https://file.sag.ceo/v4/file/0e8d4d7cc5babc59c4749108eceb263c81e3bb52b553c656da5779abf143347dc57c0f27dd9dd41e4f504758a7bc49ba2488f7a3946c253896a46c82a437f9d3.png",
    "path": "/cretop",
    "expanded": true,
    "children": [
      {
        "name": "기업 보고서 생성",
        "path": "/cretop"
      },
      {
        "name": "기업DB 목록",
        "path": "/cretop/db"
      }
    ]
  }
];


export { sideMenuList1, sideMenuList2};
