import React from "react";
import { Box, Button, Card, styled } from "@mui/material";
import { ButtonText, H5, H6, Paragraph, Small } from "components/Typography";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
// ----------------------------------
import naver from "assets/fab-naver.png";
import kakao from "assets/fab-kakao.png";
import insta from "assets/fab-insta.png";
import youtube from "assets/fab-youtube.png";
import blog from "assets/naver-blog.svg";
import { useSelector } from "react-redux";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import { accountPermissionCompare } from "utils/compareAccount";
import { useState } from "react";
import QModal from "page/modal/q-modal";
import { setSetting } from "reducer/sidebarReducer";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
// ----------------------------------

const Footer = ({ showSideBar }) => {
  const setting = useSelector((state) => state.Sidebar.setting);
  const account = useSelector((state) => state.Auth.account);
  const token = useSelector((state) => state.Auth.token);
  const dispatch = useDispatch()
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();
  
  const [openQModal, setOpenQModal] = useState(false);

  const [footer, setFooter] = useState();


  const location = useLocation();
  let sidebarWidth = "230PX";

  // switch (location.pathname) {
  //   case "/naver/jungbaksa":
  //   case "/dashboard/course":
  //   case "/dashboard/forumdetail":
  //   case "/dashboard/forumlist":
  //   case "/dashboard/calendar":
  //   case "/dashboard/namecardprofile":
  //   case "/dashboard/namecard":
  //   case "/dashboard/myinfoedit":
  //   case "/dashboard/statistics":
  //     sidebarWidth = "230px";

  //     break;
  //   default:
  //     sidebarWidth = "260px";
  // }

  // styled components
  const StyledCard = styled(Card)(({ theme, showSideBar }) => ({
    width: `calc(100% - ${isTablet ? "0px" : showSideBar ? sidebarWidth : "0px"
      })`,
    marginLeft: isTablet ? "0px" : showSideBar ? sidebarWidth : "0px",

    // marginTop: "1rem",
    backgroundColor: "#EAEAEA",
    borderRadius: 0,
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("sm")]: {
      "& > .MuiBox-root": {
        paddingBottom: 0,
      },
    },
  }));

  const navLinksRow1 = [
    "공지사항",
    "강사 지원하기",
    "자주 묻는 질문",
    "서비스 이용약관",
  ];
  const navLinksRow2 = ["개인정보처리방침", "저작권 안내", "채용"];
  const navLinksRow3 = [
    { title: "네이버 블로그", image: blog },
    { title: "Youtube", image: youtube },
  ];
  const navLinksRow4 = [
    { title: "네이버 카페", image: naver },
    { title: "인스타그램", image: insta },
  ];

  const OpenQeustionAlert = () => {
    const data = window.prompt("FOOTER 수정", setting?.footer);
    if (!data) return;
    const newSetting = { ...setting }
    newSetting.footer = data
    axios.post(`${process.env.REACT_APP_API_URI}/v4/setting`, { data: newSetting }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      dispatch(setSetting(newSetting))
    })
  }

  return (
    <StyledCard showSideBar={showSideBar}>
      <Toaster/>
      <Grid
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: { xs: "2rem 2rem", lg: "2rem 4rem" },
          justifyContent: "space-between",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        {/* first and second menu */}
        <Grid
          xs={12}
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "row", lg: "row" },
            marginBottom: { xs: 4, lg: 0 },
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {navLinksRow1.map((link, index) => (
              <Grid item key={index}>
                <H6>
                  <NavLink to={`/${link}`} style={{ color: "#151515" }}>
                    {link}
                  </NavLink>
                </H6>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {navLinksRow2.map((link, index) => (
              <Grid item key={index}>
                <H6>
                  <NavLink to={`/${link}`} style={{ color: "#151515" }}>
                    {link}
                  </NavLink>
                </H6>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* third and fourth menu */}
        <Grid
          xs={12}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: { xs: "row", lg: "row" },
            marginBottom: { xs: 4, lg: 0 },
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {navLinksRow3.map((item, image, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item.image}
                    alt={"icon"}
                    width={20}
                    style={{ marginRight: "4px", objectFit: "contain" }}
                  />
                  <H6>
                    <NavLink to={`/${item.title}`} style={{ color: "#151515" }}>
                      {item.title}
                    </NavLink>
                  </H6>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              flexBasis: { xs: "100%", lg: "48%" },
            }}
          >
            {navLinksRow4.map((item, image, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item.image}
                    alt={"icon"}
                    width={20}
                    style={{ marginRight: "4px", objectFit: "contain" }}
                  />
                  <H6>
                    <NavLink to={`/${item.title}`} style={{ color: "#151515" }}>
                      {item.title}
                    </NavLink>
                  </H6>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ minWidth: isTablet ? "inherit" : 350, width: "100%" }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {/* <Box> */}
            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: 2,
                width: "100%",
                borderRadius: 2,
                background: theme.palette.gradient.purpletoblue,
              }}
            >
              <ButtonText>문의하기</ButtonText>
            </Button>
            {/* <Small> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: isTablet ? "column" : "row",
                justifyContent: "center",
              }}
            >
              <Small
                style={{
                  textAlign: "center",
                  color: "#151515",
                  fontWeight: 400,
                  marginRight: "6px",
                }}
              >
                {t("평일 10:00 - 18:00")}
              </Small>
              {/* </Small> */}
              <Small>{t("점심시간 12 - 13시 | 주말 및 공휴일 제외")}</Small>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          padding: { xs: "0rem 2rem", lg: "0rem 4rem" },
        }}
      >
        <Box
          sx={{
            borderTop: "1px solid #CBCBCB",
            padding: "1rem 0rem 2rem 0rem",
          }}
        >
          <Small lineHeight={1} sx={{ lineHeight: 1 }}>
            {
              accountPermissionCompare(account?.type, "이사") && (
                <>
                  <ContextMenuTrigger id="footer" key={1}>
                    {setting?.footer
                      ?.split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </ContextMenuTrigger>
                  <ContextMenu id="footer">
                    <ContextMenuItem onClick={() => {
                      OpenQeustionAlert()
                    }}>푸터 수정</ContextMenuItem>
                  </ContextMenu>
                </>
              )
            }

            {!accountPermissionCompare(account?.type, "이사") && setting?.footer
              ?.split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </Small>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default Footer;
