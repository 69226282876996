import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment, useState } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import Footer from "components/Footer";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BottomBar from "components/BottomBar";

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showSideBar, setShowSideBar] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  // 사이드바 넓이 줄인것에 페이지 width 맞추기
  const location = useLocation();
  let sidebarWidth = "0px";

  const customStyle = {
    // width: `calc(100% - ${showSideBar ? sidebarWidth : "0px"})`,
    width: "100%",
    marginLeft: showSideBar ? sidebarWidth : "0px",
    paddingX: !isDesktop ? "0px" : "220px",
  };

  return (
    <Fragment>
      <DashboardSidebar
        showSideBar={showSideBar}
        showMobileSideBar={showMobileSideBar}
        setShowSideBar={() => setShowSideBar((state) => !state)}
        closeMobileSideBar={() => setShowMobileSideBar(false)}
      />
      <DashboardHeader
        setShowSideBar={() => setShowSideBar((state) => !state)}
        showSideBar={showSideBar}
        setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
      />
{isMobile &&  window.location.hostname != "booksense.club" && (
        <BottomBar setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)} showMobileSideBar={showMobileSideBar}/>
      )}

      {/* Conditionally render children or Outlet based on route */}
      {window.location.hostname == "booksense.club" ? (
        <>
        <LayoutBodyWrapper sx={customStyle}>
          {children || <Outlet />}
        </LayoutBodyWrapper>
        </>
      ) : (
        <>
        
        <LayoutBodyWrapper sx={customStyle}>
          {children || <Outlet />}
        </LayoutBodyWrapper>
          <Footer showSideBar={showSideBar} />
        </>
      )}
    </Fragment>
  );
};

export default DashboardLayout;
