import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  styled,
  Toolbar,
  useTheme,
  useMediaQuery,
  Button,
  InputBase,
} from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import SearchIcon from "icons/SearchIcon";
import { useContext, useState } from "react";
import SearchBar from "./SearchBar";
import BigSearchBar from "./BigSearchBar";
import { ButtonText, H5, H6 } from "components/Typography";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/sugar_logo.png";
import globalcss from "styles/global.css";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import WarningModal from "page/modal/alerts/WarningModal";
import CompleteModal from "page/modal/alerts/SuccessModal";
import { Logout, setLogin } from "reducer/authReducer";
import SurveyModal from "page/modal/survey-modal2";
import Request from "utils/request";
import { useEffect } from "react";
import ComplateModalV2 from "page/modal/complate-modal-v2";
import { useCookies } from "react-cookie";
import { setAccountType, setSetting, setSidebar, setSidebar2, setSidebarDump } from "reducer/sidebarReducer";
import { accountPermissionCompare } from "utils/compareAccount";
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from "rctx-contextmenu";
import IconEditModal from "components/modal/iconEditorModal";
import BigSearchBar2 from "./BigSearchBar2";
import LoginModal from "page/modal/login-modal";
import LoginModalSimple from "page/modal/login-simple";

// ------------------------------------------------
// custom styled components
const StyledToolBar = styled(Toolbar)(() => ({
  // padding: { xs: "0rem 2rem", sm: "0rem 7rem" },
  alignItems: "center",
  justifyContent: "space-between",
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const ToggleIcon = styled(Box)(({ theme, width }) => ({
  height: 3,
  margin: "5px",
  marginLeft: 0,
  width: width || 25,
  borderRadius: "10px",
  transition: "width 0.3s",
  background: theme.palette.gradient.purpletoblue
}));

const PanelContainer = styled(Box)({
  // display: "none",
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  padding: "40px 80px",
  borderRadius: "0px",
  borderTop: "1px solid #E1E1E1",
  borderBottom: "1px solid #E1E1E1",
  zIndex: 10,
});

const Row = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // marginBottom: "8px",
});

const Column = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  // marginBottom: "8px",
  // borderLeft: "2px solid #E1E1E1",
  paddingLeft: 24,
  paddingTop: 0,
});

const ColumnItem = styled(Box)({
  // width: "calc(25% - 8px)",
  fontSize: 16,
  fontWeight: 500,
  color: "#151515",
  padding: "4px",
  borderRadius: "4px",
  whiteSpace: "nowrap",
});



const DashboardHeader = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.Auth.token);
  const account = useSelector((state) => state.Auth.account);
  const setting = useSelector((state) => state.Sidebar.setting);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  //use Cookies
  const [cookies, setCookie, removeCookie] = useCookies(["authorization"]);
  const { setShowMobileSideBar, setShowSideBar } = props;

  const [showPanel, setShowPanel] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [openSearchBar, setSearchBar] = useState(false);
  const { settings, saveSettings } = useContext(SettingsContext);
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));

  const { setNodeRef, listeners, transform } = useDraggable({
    id: "draggable",
  });

  const location = useLocation();
  let sidebarWidth = "0px";

  // switch (location.pathname) {
  //   case "/naver/jungbaksa":
  //   case "/naver/course":
  //   case "/dashboard/forumdetail":
  //   case "/dashboard/forumlist":
  //   case "/dashboard/calendar":
  //   case "/dashboard/namecardprofile":
  //   case "/dashboard/namecard":
  //   case "/dashboard/myinfoedit":
  //   case "/dashboard/statistics":
  //     sidebarWidth = "230px";

  //     break;
  //   default:
  //     sidebarWidth = "260px";
  // }

  // custom styled components
  const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
    zIndex: 1201,
    boxShadow: "none",
    padding: isTablet ? "0.5rem 1rem" : "0rem 1rem",
    boxShadow: "0px 12px 12px rgba(0, 0, 0, 0.02)",
    width: `calc(100% - ${isTablet ? "0px" : sidebarWidth})`,
    marginLeft: isTablet ? "0px" : sidebarWidth,
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #E1E1E1",
    // marginBottom: "1.5rem",
    color: theme.palette.text.primary,
  }));
  const [registerPartner, setRegisterPartner] = useState(false);
  const [logout, setLogout] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [success, setSuccess] = useState(false);
  const [warning2, setWarning2] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [successJoin, setSuccessJoin] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const [showIconEdit, setShowIconEdit] = useState(false);
  const lessThan1500 = useMediaQuery("(max-width:1500px)");
  const lessThan1200 = useMediaQuery("(max-width:1200px)");
  const lessThan1300 = useMediaQuery("(max-width:1300px)");
  const lessThan900 = useMediaQuery("(max-width:900px)");
  const lessThan600 = useMediaQuery("(max-width:600px)");
  const lessThan400 = useMediaQuery("(max-width:400px)");

  const StyledInputContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    maxWidth: "1100px",
    alignItems: "center",
    height: "40px",
  }));

  const GradientBorderSVG = styled("svg")(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    "& rect": {
      fill: "none",
      stroke: "url(#gradient)",
      strokeWidth: 2.5,
      rx: lessThan1500 ? 44 : 44,
    },
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: "100%",
    height: "36px",
    fontSize: 14,
    fontWeight: 500,
    paddingTop: "3px",
    paddingLeft: "16px",
    paddingRight: "8px",
    position: "relative",
    zIndex: 1,
    "& input::placeholder": {
      fontWeight: 400,
      fontSize: 15,
      color: "#B5B7C0",
    },
  }));

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const [showLoginModal, setShowLoginModal] = useState(false);

  const login = () => {
    setShowLoginModal(true)
    // window.open('https://kauth.kakao.com/oauth/authorize?client_id='+process.env.REACT_APP_KAKAO_LOGIN_CLIENT_KEY+'&redirect_uri='+process.env.REACT_APP_API_URI+'/v4/auth/login/redirect/&response_type=code', "", "width=300px, height=300")
    // window.addEventListener('message', ((e) => {
    //   if (e.data.type == "login") {
    //     setUserAccount(e.data.user)
    //     setUserToken(e.data.access_token)
    //     setTimeout(() => {
    //       setSuccess(true)
    //     }, 10)
    //   } else if (e.data.type == "reject") {
    //     setWarning2(true)
    //   }
    // }), false);
  }

  useEffect(() => {
    const request = new Request(token)
    request.get(process.env.REACT_APP_API_URI+'/v4/auth/verify').then((res) => {
      dispatch(setLogin(token, res.data.data))
    }).catch((err) => {
      dispatch(Logout())
    })
    request.get(process.env.REACT_APP_API_URI+'/v4/sidebar').then((res) => {
      dispatch(setSidebar(res.data.data))
      dispatch(setSidebarDump(res.data.data))
    })
    request.get(process.env.REACT_APP_API_URI+'/v4/sidebar/2').then((res) => {
      dispatch(setSidebar2(res.data.data))
    })
    request.get(process.env.REACT_APP_API_URI+'/v4/setting').then((res) => {
      dispatch(setSetting(res.data.data))
    })
    request.get(process.env.REACT_APP_API_URI+'/v4/auth/type').then((res) => {
      dispatch(setAccountType(res.data))
    })
  }, []);
  return (
    <DashboardHeaderRoot position="sticky" sx={{
      height: isMobile ? "50px" : "auto",
    }}>
      {
        window.location.hostname == "booksense.club"? (
          <LoginModalSimple open={showLoginModal} setShowLoginModal={setShowLoginModal} />
        ) : (
          <LoginModal open={showLoginModal} setShowLoginModal={setShowLoginModal} />
        )
      }
      {/* <LoginModal open={showLoginModal} setShowLoginModal={setShowLoginModal} /> */}
      <WarningModal showModal={showWarningMessage} setShowModal={setShowWarningMessage} title={"파트너스 가입에 실패했습니다."} description={warningMessage} action={""} />
      <WarningModal showModal={warning2} setShowModal={setWarning2} title={"카카오톡 인증에 실패했습니다."} description={""} action={""} />
      <CompleteModal showModal={success} setShowModal={setSuccess} title={"로그인 성공"} description={"즐거운 시간되세요 :)"} onAction={() => {
        setSuccess(false)
        setShowLoginModal(false)
        dispatch(setLogin(userToken, userAccount))
      }} />
      <CompleteModal showModal={successJoin} setShowModal={setSuccessJoin} title={"파트너스 가입 성공"} description={"영업 현황 -> 내 영업 현황을 확인해주세요 :)"} onAction={() => {
        const request = new Request(token)
        request.get(process.env.REACT_APP_API_URI+'/v4/auth/verify').then((res) => {
          dispatch(setLogin(token, res.data.data))
        })
      }}/>
      <ComplateModalV2 showModal={registerPartner} setShowModal={setRegisterPartner} title={"파트너스에 가입하시겠습니까?"} description={""} onAction={() => {
        const request = new Request(token)
        request.post(process.env.REACT_APP_API_URI+'/v4/account/partner').then((res) => {
          setSuccessJoin(true)
        }).catch((err) => {
          setWarningMessage(err.response.data.message)
          setTimeout(() => {
            setShowWarningMessage(true)
          }, 10)
        })
      }}/>
      <CompleteModal showModal={logout} setShowModal={setLogout} onAction={() => {
        dispatch(Logout())
        window.location.href = '/'
      }} title={"오늘도 감사합니다 :)"} description={""} />
      <IconEditModal showModal={showIconEdit} setShowModal={setShowIconEdit} />
      <SurveyModal open={showSurveyModal} setShowModal={setShowSurveyModal} />
      {isTablet && (
        <StyledToolBar>
          <Box
            mb={0.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {downMd && (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={setShowMobileSideBar}
              >
                <ToggleIcon />
                <ToggleIcon width={18} />
                <ToggleIcon width={9} />
              </Box>
            )}
            {window.location.hostname != "booksense.club" ?  <NavLink to={`/`}>
              {
                accountPermissionCompare(account?.type, "정직원 매니저") ? (
                  <>
                  <ContextMenuTrigger id="context-logo" key={1}>
                      <img
                      height={"36px"}
                        src={setting?.logo}
                        alt={"logo"}
                        style={{
                          cursor: "pointer",
                          marginLeft: "2px",
                        }}
                      />
                  </ContextMenuTrigger>
                  <ContextMenu id="context-logo">
                    <ContextMenuItem onClick={() => {
                      setShowIconEdit(true)
                    }}>
                      아이콘 수정
                    </ContextMenuItem>
                  </ContextMenu>
                  </>
                ) : (
                  <img
                    height={"36px"}
                    src={setting?.logo}
                    alt={"logo"}
                    style={{
                      cursor: "pointer",
                      marginLeft: "2px",
                    }}
                  />
                )
              }
            </NavLink> : null}
          </Box>

          <ClickAwayListener onClickAway={() => setSearchBar(false)}>
            <Box>
              {/* <SearchBar
                open={openSearchBar}
                handleClose={() => setSearchBar(false)}
              /> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // backgroundColor: "#000000",
                  // width: '100%'
                }}
              >
                {
                  !token && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        height: "28px",
                        fontSize: 14.5,
                        borderRadius: 2,
                        marginBottom: "0.5rem",
                        maxWidth: "70px",
                        minWidth: "70px",
                        background: theme.palette.gradient.purpletoblue,
                        marginRight: "0.4rem"
                      }}
                      onClick={login}
                    >
                      <H6
                        fontWeight={600}
                        // onClick={() => setShowSignupModal(true)}
                        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      >
                        로그인
                      </H6>
                    </Button>
                  )
                }
                {
                  account?.partner_type == "미가입" && window.location.hostname == "sag.ceo" && (

                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        height: "28px",
                        fontSize: 14.5,
                        borderRadius: 2,
                        marginBottom: "0.5rem",
                        maxWidth: "95px",
                        minWidth: "95px",
                        background: theme.palette.gradient.purpletoblue,
                        marginRight: "0.4rem"
                      }}
                      onClick={() => setRegisterPartner(true)}
                    >
                      <H6
                        fontWeight={600}
                        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      >
                        파트너스 가입
                      </H6>
                    </Button>
                  )
                }
                {window.location.hostname != "booksense.club" &&<Button
                  variant="contained"
                  size="small"
                  sx={{
                    height: "28px",
                    fontSize: 14.5,
                    borderRadius: 2,
                    marginBottom: "0.5rem",
                    maxWidth: "70px",
                    minWidth: "70px",
                    background: theme.palette.gradient.purpletoblue,
                  }}
                  onClick={() => setShowSurveyModal(true)}
                >
                  <H6
                    fontWeight={600}
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    상담신청
                  </H6>
                </Button>
}
              </Box>
            </Box>
          </ClickAwayListener>
        </StyledToolBar>
      )}

      <DndContext>
        <StyledToolBar
          ref={setNodeRef}
          {...listeners}
          style={{
            width: "100%",
            overflowX: "auto",
            scrollbarWidth: "none", // For Firefox
            msOverflowStyle: "none",
            display: "flex", // Add this line
            flexDirection: "row", // For Internet Explorer and Edge
            transform: transform
              ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
              : undefined,
            WebkitOverflowScrolling: "touch", // For smoother scrolling on iOS devices
          }}
        >
          {(isTablet || isMobile) && (
            <Box
              sx={{
                display: "flex",
                width: isTablet ? "100%" : "80%",
                justifyContent: "space-between",
                alignItems: "center",
                paddingX: isTablet ? 0 : 2,
              }}
            >
              {/* {isMobile && (
                <>
                  <ClickAwayListener onClickAway={() => setSearchBar(false)}>
                    <Box>
                      {!openSearchBar && (
                        <StyledIconButton onClick={() => setSearchBar(true)}>
                          <SearchIcon
                            sx={{
                              color: "#B5B7C0",
                              marginLeft: "-6px",
                            }}
                          />
                        </StyledIconButton>
                      )}
                    </Box>
                  </ClickAwayListener>
                </>
              )} */}

              {/* {isTablet && (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => navigate("/dashboard/survey")}
                    sx={{
                      height: "28px",
                      fontSize: 14.5,
                      borderRadius: 2,
                      maxWidth: "70px",
                      minWidth: "70px",
                      whiteSpace: "nowrap",
                      borderRadius: 2,
                      backgroundColor: "primary.darkBlue",
                      "&:hover": {
                        backgroundColor: "primary.darkBlueHover",
                      },
                    }}
                  >
                    <ButtonText>상담신청</ButtonText>
                  </Button>

                  <ClickAwayListener
                    onClickAway={() => setSearchBar(false)}
                    sx={{ width: "100%" }}
                  >
                    <Box sx={{ width: "100%", padding: "0 0 0 1rem" }}>
                      <BigSearchBar
                        open={openSearchBar}
                        handleClose={() => setSearchBar(false)}
                      />
                    </Box>
                  </ClickAwayListener>
                </>
              )} */}
            </Box>
          )}

          {!isTablet && (
            <>
            {!downMd && (
                  <Box
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={setShowSideBar}
                    // onMouseEnter={() => handleMouseEnter(true)}
                    // onMouseLeave={() => handleMouseLeave(false)}
                  >
                    <ToggleIcon />
                    <ToggleIcon width={18} />
                    <ToggleIcon width={9} />
                  </Box>
                )}
              <ClickAwayListener
                onClickAway={() => setSearchBar(false)}
                sx={{ width: "100%" }}
              >
                <Box
                  ml={3}
                  mr={2}
                  my={1}
                  sx={{ width: "100%", padding: "0 0 0 0" }}
                >
                  <StyledInputContainer>
                    <GradientBorderSVG
                      viewBox={
                        true && lessThan1300
                          ? `0 0 1400 100`
                          : true && lessThan1500
                            ? `0 0 1600 100`
                            : lessThan400
                              ? `0 0 700 100`
                              : lessThan600
                                ? `0 0 1100 100`
                                : lessThan900
                                  ? `0 0 1500 100`
                                  : lessThan1200
                                    ? `0 0 1900 100`
                                    : lessThan1500
                                      ? `0 0 1800 100`
                                      : `0 0 2000 100`
                      }
                      preserveAspectRatio="none"
                    >
                      <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop
                            offset="0%"
                            style={{ stopColor: "#B187FF", stopOpacity: 1 }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: "#5C92D2",
                              stopOpacity: 1,
                            }}
                          />
                        </linearGradient>
                      </defs>
                      <rect
                        x="1.5"
                        y="1.5"
                        width="calc(100% - 3px)"
                        height="calc(100% - 3px)"
                      />
                    </GradientBorderSVG>
                    <StyledInputBase
                      placeholder="키워드를 검색해 보세요"
                      startAdornment={
                        window.location.hostname != "booksense.club" ? <NavLink to={`/`}>
                          {
                            accountPermissionCompare(account?.type, "정직원 매니저") ? (
                              <>
                                <ContextMenuTrigger id="context-logo2" key={2}>
                                  <img
                                    height={"30px"}
                                    src={setting?.logo}
                                    alt={"logo"}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "2px",
                                    }}
                                  />
                                </ContextMenuTrigger>
                                <ContextMenu id="context-logo2">
                                  <ContextMenuItem onClick={() => {
                                    setShowIconEdit(true)
                                  }}>
                                    아이콘 수정
                                  </ContextMenuItem>
                                </ContextMenu>
                              </>
                            ) : (
                              <img
                                height={"30px"}
                                src={setting?.logo}
                                alt={"logo"}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "2px",
                                }}
                              />
                            )
                          }
                        </NavLink>
                        : null
                      }
                      endAdornment={
                        <StyledIconButton onClick={() => { }}>
                          <SearchIcon
                            sx={{
                              color: "#B5B7C0",
                            }}
                          />
                        </StyledIconButton>
                      }
                    />
                  </StyledInputContainer>
                </Box>
              </ClickAwayListener>

              <Box
                sx={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingX: 2,
                }}
              >

                {
                  !token && (
                    <H6
                      fontWeight={600}
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        background: theme.palette.gradient.purpletoblue,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        display: "inline-block",
                        marginRight: "1rem"
                      }}
                      onClick={login}
                    >
                      로그인
                    </H6>
                  )
                }
                {
                  account?.partner_type == "미가입" && window.location.hostname == "sag.ceo" && (

                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        height: "28px",
                        fontSize: 14.5,
                        borderRadius: 2,
                        maxWidth: "95px",
                        minWidth: "95px",
                        background: theme.palette.gradient.purpletoblue,
                        marginRight: "0.4rem"
                      }}
                      onClick={() => setRegisterPartner(true)}
                    >
                      <H6
                        fontWeight={600}
                        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      >
                        파트너스 가입
                      </H6>
                    </Button>
                  )
                }

                {window.location.hostname != "booksense.club" && <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 1,
                    height: "28px",
                    fontSize: 14.5,
                    borderRadius: 2,
                    maxWidth: "70px",
                    minWidth: "70px",
                    borderRadius: 2,
                    background: theme.palette.gradient.purpletoblue
                  }}
                  onClick={() => setShowSurveyModal(true)}
                >
                  <H6
                    fontWeight={600}
                    // onClick={() => setShowSignupModal(true)}
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    상담신청
                  </H6>
                </Button>
                }
              </Box>
            </>
          )}
        </StyledToolBar>
        {/* </div> */}
      </DndContext>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
